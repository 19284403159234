import * as React from 'react';
import { Datagrid, ImageField, NumberField, TextField } from 'react-admin';
import './InvitationDatagrid.scss';

export const InvitationDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="invitations-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<TextField source="name" label="NAME"/>
			<ImageField source="imageUrl" label="IMAGE"/>
			<TextField source="textColor" label="TEXT_COLOR"/>
			<TextField source="ctaBackgroundColor" label="CTA_BACKGROUND_COLOR"/>
			<TextField source="ctaTextColor" label="CTA_TEXT_COLOR"/>
		</Datagrid>
	);
};
