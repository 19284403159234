import * as React from 'react';
import {
	BooleanField,
	EditButton,
	EmailField,
	FunctionField,
	Identifier,
	Labeled,
	ReferenceField,
	TextField
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { PriceField } from '../components/PriceField';
import { PhoneField } from '../components/PhoneField';
import { TranslateField } from '../components/TranslateField';

import { Purchase } from './Purchase';

const purchaseStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface PurchaseListProps {
	ids?: Identifier[];
	data?: { [key: number]: Purchase };
	basePath?: string;
}

export const PurchaseList = (props: PurchaseListProps) => {
	const { ids, data } = props;
	const classes = purchaseStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="purchases"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="TRANSACTION_ID" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="transactionId" label="TRANSACTION_ID"/>
						</Labeled>
						<Labeled label="PRODUCT" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="productId" label="FORUM" reference="products">
								<TextField source="title"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="EVENT" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="eventId" label="FORUM" reference="events">
								<FunctionField
									render={(record) => record.children?.length ? record.children.map(child => child.fullName).join(', ') : record.id}/>
							</ReferenceField>

						</Labeled>
						<Labeled label="PERSONAL_ID" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="customer.personalId" label="PERSONAL_ID"/>
						</Labeled>
						<Labeled label="CUSTOMER_NAME" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="customer.name" label="CUSTOMER_NAME"/>
						</Labeled>
						<Labeled label="CUSTOMER_CHILD_NAME" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="customer.childName" label="CUSTOMER_CHILD_NAME"/>
						</Labeled>
						<Labeled label="EMAIL" sx={classes.cardContentRow}>
							<EmailField record={data[id]} source="customer.email" label="EMAIL"/>
						</Labeled>
						<Labeled label="PHONE_NUMBER" sx={classes.cardContentRow}>
							<PhoneField record={data[id]} source="customer" label="PHONE_NUMBER"/>
						</Labeled>
						<Labeled label="MESSAGE" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="customer.message" label="MESSAGE"/>
						</Labeled>
						<Labeled label="SUM" sx={classes.cardContentRow}>
							<PriceField record={data[id]} source="sum" label="SUM"/>
						</Labeled>
						<Labeled label="ADDRESS" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="address.addressStr" label="ADDRESS"/>
						</Labeled>
						<Labeled label="STATUS" sx={classes.cardContentRow}>
							<TranslateField record={data[id]} source="status" label="STATUS"/>
						</Labeled>
						<Labeled label="IS_SETTLED" sx={classes.cardContentRow}>
							<BooleanField record={data[id]} source="isSettled" label="IS_SETTLED"/>
						</Labeled>
						<Labeled label="TRANSACTION_TOKEN" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="transactionToken" label="TRANSACTION_TOKEN"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
