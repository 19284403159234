import * as React from 'react';
import {
	AutocompleteInput,
	Edit,
	EditProps,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	useRefresh
} from 'react-admin';
import { EProductItemStatus } from '../enums';

export const ProductItemEdit = (props: EditProps): React.ReactElement => {
	const refresh = useRefresh();

	return (
		<Edit {...props} mutationMode="pessimistic" redirect={(
			resource?: string,
			id?: any,
			data?: any,
			state?: object
		) => {
			refresh();

			return `events/${data.eventId}/show/product-items`;
		}}

		>
			<SimpleForm>
				<ReferenceInput
					label="PRODUCT"
					source="productId" reference="products"
					alwaysOn
				>
					<AutocompleteInput label="PRODUCT" optionText="title"/>
				</ReferenceInput>

				<TextInput label="NOTE" source="note"/>
				<SelectInput source="status" label="STATUS" choices={Object.keys(EProductItemStatus).map(key => ({
					id: key,
					name: EProductItemStatus[key]
				}))}/>
				{/*<Labeled label="PURCHASED"><BooleanField label="PURCHASED" source="purchased"/></Labeled>*/}
				{/*<Labeled label="TOTAL_PURCHASED_SUM"><NumberField label="TOTAL_PURCHASED_SUM"*/}
				{/*                                                  source="totalPurchasedSum"/></Labeled>*/}
				{/*<Labeled label="NUMBER_OF_PURCHASES"><NumberField label="NUMBER_OF_PURCHASES"*/}
				{/*                                                  source="numberOfPurchases"/></Labeled>*/}
			</SimpleForm>
		</Edit>
	);
};
