import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, } from 'react-admin';
import { EmployeeList } from './EmployeeList';
import { EmployeeDatagrid } from './EmployeeDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Employee } from './Employee';

export * from './Employee';
export * from './EmployeeCreate';
export * from './EmployeeDatagrid';
export * from './EmployeeEdit';
export * from './EmployeeList';
export * from './EmployeeShow';

const EmployeesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode='pessimistic'/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'fullName', header: 'שם' },
	{ key: 'id', header: '#' },
	{ key: 'phone', header: 'טלפון' },
	{ key: 'id', header: '#' },
	{ key: 'email', header: 'אימייל' },
];

export const EmployeesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Employee) => entity;

	const employeesFilter = [
		<DateInput source='from__createdAt' label='FROM_CREATED_AT' alwaysOn/>,
		<DateInput source='to__createdAt' label='TO_CREATED_AT' alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('עובדי בול', exportFields, exportDataMapper)}
				{...props}
				filters={employeesFilter}
				bulkActionButtons={<EmployeesBulkActionButtons/>}
				title='EMPLOYEES'
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <EmployeeList/> : <EmployeeDatagrid/>}
			</List>
		</Fragment>
	);
};
