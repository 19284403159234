import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, } from 'react-admin';
import { MissingProductList } from './MissingProductList';
import { MissingProductDatagrid } from './MissingProductDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { MissingProduct } from './MissingProduct';

export * from './MissingProduct';
export * from './MissingProductCreate';
export * from './MissingProductDatagrid';
export * from './MissingProductEdit';
export * from './MissingProductList';
export * from './MissingProductShow';

const MissingProductsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode='pessimistic'/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'title', header: 'כותרת' },
	{ key: 'id', header: '#' },
	{ key: 'description', header: 'תיאור' },
	{ key: 'id', header: '#' },
	{ key: 'canBeFoundIn', header: 'ניתן למצוא ב..' },
	{ key: 'id', header: '#' },
	{ key: 'imageUrl', header: 'תמונה' },
	{ key: 'id', header: '#' },
	{ key: 'parentId', header: 'הורה' },
];

export const MissingProductsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: MissingProduct) => entity;

	const missingProductsFilter = [
		<DateInput source='from__createdAt' label='FROM_CREATED_AT' alwaysOn/>,
		<DateInput source='to__createdAt' label='TO_CREATED_AT' alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('מוצרים חסרים', exportFields, exportDataMapper)}
				{...props}
				filters={missingProductsFilter}
				bulkActionButtons={<MissingProductsBulkActionButtons/>}
				title='MISSING_PRODUCTS'
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <MissingProductList/> : <MissingProductDatagrid/>}
			</List>
		</Fragment>
	);
};
