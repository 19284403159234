export enum EUserType {
	Admin = 'Admin',
	Regular = 'Regular',
	Vendor = 'Vendor',
	Employee = 'Employee',
}

export enum ESearchType {
	EQUALS = 'EQUALS',
	CONTAINS = 'CONTAINS',
	BETWEEN = 'BETWEEN'
}

export enum EUserStatus {
	Pending = 'Pending',
	Approved = 'Approved'
}

export enum NotificationType {
	SMS = 'SMS',
	Email = 'Email',
	All = 'All'
}

export enum EGender {
	Male = 'Male',
	Female = 'Female'
}

export enum EPlatform {
	AdminPanel = 'AdminPanel',
	App = 'App',
	Web = 'Web'
}

export enum EPurchaseStatus {
	Purchased = 'Purchased',
	Out_Of_Stock = 'OutOfStock',
	In_Delivery = 'InDelivery',
	Completed = 'Completed'
}

export enum EGiftType {
	Personal = 'Personal',
	Shared = 'Shared'
}

export enum EPurchasePlatform {
	Regular = 'Regular',
	Bit = 'Bit'
}

export enum EProductItemStatus {
	NotPurchased = 'NotPurchased',
	UnHandled = 'UnHandled',
	Canceled = 'Canceled',
	InProgress = 'InProgress',
	Handled = 'Handled'
}

export enum EEventSegment {
	UnHandled = 'UnHandled',
	Future = 'Future',
	History = 'History',
}
