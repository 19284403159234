import * as React from 'react';
import {
	BooleanField,
	ChipField,
	Datagrid,
	DateField,
	ImageField,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	SingleFieldList,
	TextField,
	UrlField,
	WithRecord
} from 'react-admin';
import { PriceField } from '../components/PriceField';
import { Event } from './Event';
import './EventDatagrid.scss';

export const EventDatagrid = (): React.ReactElement => {
	const getBackground = (event: Event): string => {
		if (event.parent?.user?.isBlocked) {
			return '#ffaaaa';
		}

		if (new Date(event.date)?.getTime() < Date.now()) {
			if (event.allPurchasesHandled) {
				return 'lightgray';
			} else {
				return '#ffaaaa';
			}
		} else {
			if (event.allPurchasesHandled) {
				return '#aaffaa';
			} else {
				return '#ffaaaa';
			}
		}
	};

	return (
		<Datagrid rowClick="show" className="events-table" optimized
		          rowStyle={(row: any) => ({
			          borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb',
			          backgroundColor: getBackground(row)
		          })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<ReferenceField
				sortable={false}
				source="parentId" reference="parents"
				label="PARENT">
				<TextField source="fullName"/>
			</ReferenceField>
			<ReferenceArrayField sortable={false} source="childrenIds" label="CHILDREN" reference="children">
				<SingleFieldList>
					<ChipField source="fullName"/>
				</SingleFieldList>
			</ReferenceArrayField>
			<TextField sortable={false} source="address.addressStr" label="ADDRESS"/>
			<DateField locales="he-IL" source="date" label="DATE"/>
			<WithRecord label="LINK" render={record => <UrlField source="token"
			                                                     href={`https://bul-gift.com/events/${record.token}`}/>}/>
			<TextField source="locationRemarks" label="LOCATION_REMARKS"/>
			<ImageField source="backgroundImageUrl" label="BACKGROUND_IMAGE"/>
			<PriceField sortable={false} source="totalSum" label="TOTAL_SUM"/>
			<NumberField sortable={false} source="numberOfParticipants" label="NUMBER_OF_PARTICIPANTS"/>
			<BooleanField sortable={false} source="didSkipProviders" label="DID_SKIP_PROVIDERS"/>
			<BooleanField sortable={false} source="allPurchasesHandled" label="ALL_PURCHASES_HANDLED"/>
			<ReferenceField source="kindergartenId" label="KINDERGARTEN"
			                reference="kindergartens">
				<TextField source="name"/>
			</ReferenceField>
		</Datagrid>
	);
};
