import * as React from 'react';
import { Edit, EditProps, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const BulPostEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="title" label="TITLE"/>
				<TextInput multiline source="description" label="DESCRIPTION"/>
				<UploadImage source="imageUrl" label="IMAGE"/>
				<TextInput source="link" label="LINK" defaultValue={null}/>
			</SimpleForm>
		</Edit>
	);
};
