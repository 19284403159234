import * as React from 'react';
import { AutocompleteInput, Edit, EditProps, NumberInput, ReferenceInput, SimpleForm, TextInput, } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const MissingProductEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode='pessimistic'>
			<SimpleForm>
                <NumberInput source='id' label='#' disabled/>
                <TextInput source='title' label='TITLE'/>
                <TextInput source='description' label='DESCRIPTION'/>
                <TextInput source='canBeFoundIn' label='CAN_BE_FOUND_IN'/>
                <UploadImage source='imageUrl' label='IMAGE'/>
                <ReferenceInput
                    label='PARENT'
                    source='parentId' reference='parents'
                    alwaysOn
                >
                    <AutocompleteInput label='PARENT' optionText='fullName'/>
               </ReferenceInput>
                
			</SimpleForm>
		</Edit>
	);
};
