import * as React from 'react';
import {
	BooleanField,
	ChipField,
	EditButton,
	Identifier,
	ImageField,
	Labeled,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	SingleFieldList,
	TextField,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Product } from './Product';
import { TranslateField } from '../components/TranslateField';
import { EUserType } from '../enums';

const productStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface ProductListProps {
	ids?: Identifier[];
	data?: { [key: number]: Product };
	basePath?: string;
}

export const ProductList = (props: ProductListProps) => {
	const { ids, data } = props;
	const classes = productStyle;
	const { permissions } = usePermissions();

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="products"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="SKU" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="sku" label="SKU"/>
						</Labeled>
						<Labeled label="TITLE" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="title" label="TITLE"/>
						</Labeled>
						<Labeled label="DESCRIPTION" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="description" label="DESCRIPTION"/>
						</Labeled>
						<Labeled label="SHORT_DESCRIPTION" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="shortDescription" label="SHORT_DESCRIPTION"/>
						</Labeled>
						<Labeled label="IMAGE" sx={classes.cardContentRow}>
							<ImageField record={data[id]} source="mainImageUrl" label="IMAGE"/>
						</Labeled>
						<Labeled label="GALLERY" sx={classes.cardContentRow}>
							<ImageField record={data[id]} source="gallery" label="GALLERY"/>
						</Labeled>
						{permissions === EUserType.Admin &&
                            <Labeled label="VENDOR" sx={classes.cardContentRow}>
                                <ReferenceField record={data[id]} source="vendorId" label="FORUM" reference="vendors">
                                    <TextField source="name"/>
                                </ReferenceField>
                            </Labeled>
						}
						<Labeled label="CATEGORY" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="categoryId" label="FORUM"
							                reference="product-categories">
								<TextField source="name"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="SUB_CATEGORY" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="subCategoryId" label="FORUM"
							                reference="product-sub-categories">
								<TextField source="name"/>
							</ReferenceField>
						</Labeled>
						<Labeled label="TAGS" sx={classes.cardContentRow}>
							<ReferenceArrayField record={data[id]} source="tagsIds" label="TAGS"
							                     reference="product-tags">
								<SingleFieldList>
									<ChipField source="name"/>
								</SingleFieldList>
							</ReferenceArrayField>
						</Labeled>
						<Labeled label="GIFT_TYPE" sx={classes.cardContentRow}>
							<TranslateField record={data[id]} source="giftType" label="GIFT_TYPE"/>
						</Labeled>
						<Labeled label="PRICE" sx={classes.cardContentRow}>
							<NumberField record={data[id]} source="price" label="PRICE"/>
						</Labeled>
						<Labeled label="IS_IN_STOCK" sx={classes.cardContentRow}>
							<BooleanField record={data[id]} source="isInStock" label="IS_IN_STOCK"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
