import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput, usePermissions } from 'react-admin';
import { ProductCategoryList } from './ProductCategoryList';
import { ProductCategoryDatagrid } from './ProductCategoryDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { ProductCategory } from './ProductCategory';
import { EUserType } from '../enums';

export * from './ProductCategory';
export * from './ProductCategoryCreate';
export * from './ProductCategoryDatagrid';
export * from './ProductCategoryEdit';
export * from './ProductCategoryList';
export * from './ProductCategoryShow';

const ProductCategoriesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' },
	{ key: 'imageUrl', header: 'תמונה' }
];

export const ProductCategoriesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: ProductCategory) => entity;
	const { permissions } = usePermissions();

	const productCategoriesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('קטגוריות מוצרים', exportFields, exportDataMapper)}
				{...props}
				filters={productCategoriesFilter}
				bulkActionButtons={permissions === EUserType.Admin ? <ProductCategoriesBulkActionButtons/> : <></>}
				hasCreate={permissions === EUserType.Admin}
				hasEdit={permissions === EUserType.Admin}
				title="PRODUCT_CATEGORIES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ProductCategoryList/> : <ProductCategoryDatagrid/>}
			</List>
		</Fragment>
	);
};
