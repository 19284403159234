import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps } from 'react-admin';
import { InvitationList } from './InvitationList';
import { InvitationDatagrid } from './InvitationDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Invitation } from './Invitation';

export * from './Invitation';
export * from './InvitationCreate';
export * from './InvitationDatagrid';
export * from './InvitationEdit';
export * from './InvitationList';
export * from './InvitationShow';

const InvitationsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'כותרת' },
	{ key: 'id', header: '#' },
	{ key: 'imageUrl', header: 'תמונה' }
];

export const InvitationsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Invitation) => entity;

	const invitationsFilter = [
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('תצוגת הזמנות', exportFields, exportDataMapper)}
				{...props}
				filters={invitationsFilter}
				bulkActionButtons={<InvitationsBulkActionButtons/>}
				title="INVITATIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <InvitationList/> : <InvitationDatagrid/>}
			</List>
		</Fragment>
	);
};
