import * as React from 'react';
import { DateField, ImageField, NumberField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

export const ServiceProviderCategoryShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="name" label="NAME"/>
				<ImageField source="imageUrl" label="IMAGE"/>
			</SimpleShowLayout>
		</Show>
	);
};
