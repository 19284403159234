import * as React from 'react';
import { EmailField, NumberField, Show, ShowProps, SimpleShowLayout, TextField, } from 'react-admin';
import { PhoneField } from '../components/PhoneField';

export const EmployeeShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<TextField source="fullName" label="FULL_NAME"/>
				<PhoneField source="phone" label="PHONE"/>
				<EmailField source="email" label="EMAIL"/>
				<TextField source="password" label="PASSWORD"/>
			</SimpleShowLayout>
		</Show>
	);
};
