import * as React from 'react';
import { AutocompleteArrayInput, Create, CreateProps, ReferenceArrayInput, SimpleForm, TextInput } from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';

export const ParentCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="fullName" label="FULL_NAME"/>
				<TextInput source="user.phone" label="PHONE"/>
				<GoogleAddressInput source="address.addressStr" label="ADDRESS"/>
				<ReferenceArrayInput source="childrenIds" label="CHILDREN"
				                     reference="children">
					<AutocompleteArrayInput label="CHILDREN" source="fullName"/>
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};
