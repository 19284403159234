import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	ReferenceInput
} from 'react-admin';
import { ServiceProviderLeadList } from './ServiceProviderLeadList';
import { ServiceProviderLeadDatagrid } from './ServiceProviderLeadDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { ServiceProviderLead } from './ServiceProviderLead';

export * from './ServiceProviderLead';
export * from './ServiceProviderLeadCreate';
export * from './ServiceProviderLeadDatagrid';
export * from './ServiceProviderLeadEdit';
export * from './ServiceProviderLeadList';
export * from './ServiceProviderLeadShow';

const ServiceProviderLeadsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'serviceProviderId', header: 'נותן שירות' },
	{ key: 'eventId', header: 'אירוע' }
];

export const ServiceProviderLeadsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: ServiceProviderLead) => entity;

	const serviceProviderLeadsFilter = [
		<ReferenceInput
			label="SERVICE_PROVIDER"
			source="serviceProviderId" reference="service-providers"
			alwaysOn
		>
			<AutocompleteInput label="SERVICE_PROVIDER" optionText="name"/>
		</ReferenceInput>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('לידים נותני שירות', exportFields, exportDataMapper)}
				{...props}
				filters={serviceProviderLeadsFilter}
				bulkActionButtons={<ServiceProviderLeadsBulkActionButtons/>}
				title="SERVICE_PROVIDER_LEADS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ServiceProviderLeadList/> : <ServiceProviderLeadDatagrid/>}
			</List>
		</Fragment>
	);
};
