import * as React from 'react';
import { Create, CreateProps, DateTimeInput, required, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const AdCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput isRequired validate={[required()]} source="title" label="TITLE"/>
				<UploadImage validate={[required()]} source="imageUrl" label="IMAGE"/>
				<DateTimeInput validate={[required()]} source="startDate" label="START_DATE"/>
				<DateTimeInput validate={[required()]} source="finishDate" label="FINISH_DATE"/>
				<TextInput type="url" source="link" label="LINK" defaultValue={null}/>
				<TextInput source="buttonText" label="BUTTON_TEXT" defaultValue={null}/>
			</SimpleForm>
		</Create>
	);
};
