import * as React from 'react';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import CreditCard from '@mui/icons-material/CreditCard';
import Check from '@mui/icons-material/Check';
import { Drawer } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { PriceField } from '../components/PriceField';
import { PhoneField } from '../components/PhoneField';
import { TranslateField } from '../components/TranslateField';
import './PurchaseDatagrid.scss';
import { ProductSimpleShow } from '../product';
import { Purchase } from './Purchase';
import { apiUrl, httpClient } from '../providers/data.provider';
import {
	ArrayField,
	Button,
	Datagrid,
	DateField,
	EmailField,
	FunctionField,
	Labeled,
	NumberField,
	ReferenceField,
	SingleFieldList,
	TextField,
	useRecordContext
} from 'react-admin';

export const PurchaseDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="purchases-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="transactionId"
			           label="TRANSACTION_ID"/>
			{/*<WithRecord label="PRODUCT" render={(record) => (record.productId ?*/}
			{/*	<ProductPreviewButton id={record.productId}/> : <></>)}/>*/}
			<div style={{ display: 'block' }}>
				<ArrayField source="productItems" label="PRODUCTS">
					<SingleFieldList>
						<ProductPreviewButton/>
					</SingleFieldList>
				</ArrayField>
			</div>

			<ReferenceField source="eventId" reference="events" label="EVENT">
				<FunctionField
					render={(record) => record.children?.length ? record.children.map(child => child.fullName).join(', ') : record.id}/>
			</ReferenceField>

			<TextField source="customer.personalId" label="PERSONAL_ID"/>
			<TextField source="customer.name" label="CUSTOMER_NAME"/>
			<TextField source="customer.childName" label="CUSTOMER_CHILD_NAME"/>
			<Labeled label="SETTLE"><SettleButton label={false}/></Labeled>
			<EmailField source="customer.email" label="EMAIL"/>
			<PhoneField source="customer.phoneNumber" label="PHONE_NUMBER"/>
			<TextField source="customer.message" label="MESSAGE"/>
			<PriceField source="sum" label="SUM"/>
			<PriceField source="deliverySum" label="DELIVERY_SUM"/>
			<TextField source="address.addressStr" label="ADDRESS"/>
			<TranslateField source="status" label="STATUS"/>
		</Datagrid>
	);
};

const ProductPreviewButton = () => {
	const productItem = useRecordContext();

	console.log(productItem);

	const [showPanel, setShowPanel] = useState(false);

	const handleClick = (event) => {
		console.log(event);

		event.stopPropagation();
		event.preventDefault();
		setShowPanel(true);
	};

	const handleCloseClick = (event) => {
		console.log(event);

		event.stopPropagation();
		event.preventDefault();
		setShowPanel(false);
	};

	return (
		<>
			<Button onClick={handleClick} label={productItem.product?.title}>
				<Visibility/>
			</Button>
			<Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
				<div>
					<Button label="CLOSE" onClick={handleCloseClick}>
						<KeyboardArrowRight/>
					</Button>
				</div>
				<ProductSimpleShow record={productItem.product}/>
			</Drawer>
		</>
	);
};

const SettleButton = (props: { label: string | boolean }) => {
	const purchase: Purchase = useRecordContext();

	const settle = (event) => {
		event.stopPropagation();
		event.preventDefault();

		httpClient(`${apiUrl}/admin/purchases/${purchase.id}/settle`, { method: 'POST' });
	};

	return <>
		{
			purchase.isSettled ?
				<div style={{
					borderRadius: '50%',
					height: '2rem',
					width: '2rem',
					backgroundColor: '#AAFFAA',
					position: 'relative'
				}}>
					<Check style={{
						color: 'white',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)'
					}}/>
				</div>
				:
				<Button onClick={settle} label="SETTLE">
					<CreditCard/>
				</Button>
		}
	</>;
};

