import * as React from 'react';
import { UploadImage } from '../components/UploadImage';
import { EGiftType, EUserType } from '../enums';
import {
	ArrayInput,
	AutocompleteInput,
	BooleanInput,
	Create,
	CreateProps,
	FormDataConsumer,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	usePermissions,
	useTranslate
} from 'react-admin';

export const ProductCreate = (props: CreateProps): React.ReactElement => {
	const { permissions } = usePermissions();
	const translator = useTranslate();

	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="sku" label="SKU"/>
				<TextInput source="title" label="TITLE"/>
				<TextInput source="description" label="DESCRIPTION"/>
				<TextInput source="shortDescription" label="SHORT_DESCRIPTION"/>
				<UploadImage source="mainImageUrl" label="IMAGE"/>
				<UploadImage multiple source="gallery" label="GALLERY"/>

				{permissions === EUserType.Admin ? <ReferenceInput
					label="VENDOR"
					source="vendor" reference="vendors"
					alwaysOn
				>
					<AutocompleteInput label="VENDOR" optionText="name"/>
				</ReferenceInput> : <></>}

				<ReferenceInput
					label="CATEGORY"
					source="categoryId" reference="product-categories"
					alwaysOn
				>
					<AutocompleteInput label="CATEGORY" optionText="name"/>
				</ReferenceInput>

				<FormDataConsumer>
					{
						({ formData }) => {
							const categoryId = formData.categoryId;

							return (
								<ReferenceInput defaultValue={null}
								                label="SUB_CATEGORY"
								                source="subCategoryId"
								                filter={categoryId ? { categoryId } : undefined}
								                reference="product-sub-categories"
								>
									<AutocompleteInput defaultValue={null}
									                   label="SUB_CATEGORY"
									                   optionText="name"/>
								</ReferenceInput>
							);
						}
					}
				</FormDataConsumer>

				<ArrayInput source="tags" label="TAGS">
					<SimpleFormIterator>
						<TextInput label="TAG" source="name"/>
					</SimpleFormIterator>
				</ArrayInput>
				<SelectInput source="giftType" label="GIFT_TYPE" choices={Object.keys(EGiftType).map(key => ({
					id: key,
					name: translator(EGiftType[key])
				}))}/>
				<NumberInput source="price" label="PRICE"/>
				<BooleanInput source="isInStock" label="IS_IN_STOCK"/>
			</SimpleForm>
		</Create>
	);
};
