import * as React from 'react';
import {
	ChipField,
	Datagrid,
	DateField,
	NumberField,
	ReferenceArrayField,
	SingleFieldList,
	TextField, usePermissions
} from 'react-admin';
import './ParentDatagrid.scss';
import { PhoneField } from '../components/PhoneField';
import { EUserType } from '../enums';

export const ParentDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick={permissions === EUserType.Admin ? 'edit' : 'show'} className="parents-table" optimized
		          rowStyle={(row: any) => ({
			          borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb',
			          backgroundColor: row.isBlocked ? '#ffaaaa' : null
		          })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="fullName" label="FULL_NAME"/>
			<PhoneField source="user.phone" label="PHONE"/>
			<TextField source="address.addressStr" label="ADDRESS"/>
			<ReferenceArrayField source="childrenIds" label="CHILDREN"
			                     reference="children">
				<SingleFieldList>
					<ChipField source="fullName"/>
				</SingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	);
};
