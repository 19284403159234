import React from 'react';
import Rule from '@mui/icons-material/Rule';
import { MissingProductCreate, MissingProductEdit, MissingProductsData, MissingProductShow } from './missing-product';

import AdsClick from '@mui/icons-material/AdsClick';
import { AdUserClicksData, AdUserClickShow } from './ad-user-click';

import Badge from '@mui/icons-material/Badge';
import { EmployeeCreate, EmployeeEdit, EmployeesData, EmployeeShow } from './employee';

import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import BabyChangingStation from '@mui/icons-material/BabyChangingStation';
import { KindergartenCreate, KindergartenEdit, KindergartensData, KindergartenShow } from './kindergarten';

import Article from '@mui/icons-material/Article';
import { InvitationCreate, InvitationEdit, InvitationsData, InvitationShow } from './invitation';

import DynamicFeed from '@mui/icons-material/DynamicFeed';
import { BulPostCreate, BulPostEdit, BulPostsData, BulPostShow } from './bul-post';

import { Navigate, Route } from 'react-router-dom';

import CreditCard from '@mui/icons-material/CreditCard';
import { PurchaseCreate, PurchaseEdit, PurchasesData, PurchaseShow } from './purchase';

import ConfirmationNumber from '@mui/icons-material/ConfirmationNumber';
import {
	ServiceProviderLeadCreate,
	ServiceProviderLeadEdit,
	ServiceProviderLeadsData,
	ServiceProviderLeadShow
} from './service-provider-lead';

import Cake from '@mui/icons-material/Cake';
import {
	ServiceProviderCreate,
	ServiceProviderEdit,
	ServiceProvidersData,
	ServiceProviderShow
} from './service-provider';

import Category from '@mui/icons-material/Category';
import {
	ServiceProviderCategoriesData,
	ServiceProviderCategoryCreate,
	ServiceProviderCategoryEdit,
	ServiceProviderCategoryShow
} from './service-provider-category';

import ChildCare from '@mui/icons-material/ChildCare';
import { ChildCreate, ChildEdit, ChildrenData, ChildShow } from './child';

import FamilyRestroom from '@mui/icons-material/FamilyRestroom';
import { ParentCreate, ParentEdit, ParentsData, ParentShow } from './parent';

import LocalShipping from '@mui/icons-material/LocalShipping';
import { VendorCreate, VendorEdit, VendorsData, VendorShow } from './vendor';

import Tag from '@mui/icons-material/Tag';
import { ProductTagCreate, ProductTagEdit, ProductTagsData, ProductTagShow } from './product-tag';

import {
	ProductSubCategoriesData,
	ProductSubCategoryCreate,
	ProductSubCategoryEdit,
	ProductSubCategoryShow
} from './product-sub-category';
import {
	ProductCategoriesData,
	ProductCategoryCreate,
	ProductCategoryEdit,
	ProductCategoryShow
} from './product-category';

import Cart from '@mui/icons-material/ShoppingCart';
import { ProductCreate, ProductEdit, ProductsData, ProductShow } from './product';
import Event from '@mui/icons-material/Event';
import { EventCreate, EventEdit, EventsData, EventShow } from './event';
import Notifications from '@mui/icons-material/Notifications';
import Star from '@mui/icons-material/Star';
import { i18nProvider } from './providers/i18n.provider';
import DataProvider from './providers/data.provider';
import { jwtAuthProvider } from './providers/auth.provider';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { theme } from './theme';
import { CustomLoginPage } from './auth/Login';
import { CustomLayout } from './components/CustomLayout';
import { Dashboard } from './components/dashboard/Dashboard';
import './App.scss';
import { NotificationsForm } from './notifications/NotificationsForm';
import { EEventSegment, EUserType } from './enums';
import { ProductItemEdit } from './event/ProductItemEdit';
import { ProductItemCreate } from './event/ProductItemCreate';
import { AdCreate, AdEdit, AdsData, AdShow } from './ad';

const App = (): React.ReactElement => {
	return (
		<div className="App" dir="rtl">
			<Admin
				loginPage={CustomLoginPage}
				theme={theme}
				layout={CustomLayout}
				dashboard={({ permissions }) => permissions === EUserType.Admin ? <Dashboard/> :
					<Navigate to="/products"/>}
				title={'אפליקציית בול'}
				dataProvider={DataProvider}
				authProvider={jwtAuthProvider}
				i18nProvider={i18nProvider}>
				<Resource
					icon={Rule}
					name='missing-products'
					options={{ label: 'MISSING_PRODUCTS' }}
					list={MissingProductsData}
					edit={MissingProductEdit}
					create={MissingProductCreate}
					show={MissingProductShow}
				/>

				<Resource
					icon={AdsClick}
					name='ad-user-clicks'
					options={{ label: 'AD_USER_CLICKS' }}
					list={AdUserClicksData}
					// edit={AdUserClickEdit}
					// create={AdUserClickCreate}
					show={AdUserClickShow}
				/>

				<Resource
					icon={Badge}
					name="employees"
					options={{ label: 'EMPLOYEES' }}
					list={EmployeesData}
					edit={EmployeeEdit}
					create={EmployeeCreate}
					show={EmployeeShow}
				/>

				{permissions => [
					<Resource
						icon={CreditCard}
						name="purchases"
						options={{ label: 'PURCHASES' }}
						list={PurchasesData}
						edit={PurchaseEdit}
						create={PurchaseCreate}
						show={PurchaseShow}
					/>,

					<Resource
						icon={Article}
						name="invitations"
						options={{ label: 'INVITATIONS' }}
						list={InvitationsData}
						edit={InvitationEdit}
						create={InvitationCreate}
						show={InvitationShow}
					/>,

					<Resource
						icon={DynamicFeed}
						name="bul-posts"
						options={{ label: 'BUL_POSTS' }}
						list={BulPostsData}
						edit={BulPostEdit}
						create={BulPostCreate}
						show={BulPostShow}
					/>,

					<Resource
						icon={ConfirmationNumber}
						name="service-provider-leads"
						options={{ label: 'SERVICE_PROVIDER_LEADS' }}
						list={ServiceProviderLeadsData}
						edit={ServiceProviderLeadEdit}
						create={ServiceProviderLeadCreate}
						show={ServiceProviderLeadShow}
					/>,

					<Resource
						icon={BorderOuterIcon}
						name="ads"
						options={{ label: 'ADS' }}
						list={AdsData}
						edit={AdEdit}
						create={AdCreate}
						show={AdShow}
					/>,

					<Resource
						icon={Cake}
						name="service-providers"
						options={{ label: 'SERVICE_PROVIDERS' }}
						list={ServiceProvidersData}
						edit={ServiceProviderEdit}
						create={ServiceProviderCreate}
						show={ServiceProviderShow}
					/>,

					<Resource
						icon={Category}
						name="service-provider-categories"
						options={{ label: 'SERVICE_PROVIDER_CATEGORIES' }}
						list={ServiceProviderCategoriesData}
						edit={ServiceProviderCategoryEdit}
						create={ServiceProviderCategoryCreate}
						show={ServiceProviderCategoryShow}
					/>,

					<Resource
						icon={ChildCare}
						name="children"
						options={{ label: 'CHILDREN' }}
						list={ChildrenData}
						edit={ChildEdit}
						create={ChildCreate}
						show={ChildShow}
					/>,

					<Resource
						icon={FamilyRestroom}
						name="parents"
						options={{ label: 'PARENTS' }}
						list={ParentsData}
						edit={ParentEdit}
						create={ParentCreate}
						show={ParentShow}
					/>,

					<Resource
						icon={LocalShipping}
						name="vendors"
						options={{ label: 'VENDORS' }}
						list={VendorsData}
						edit={VendorEdit}
						create={VendorCreate}
						show={VendorShow}
					/>,

					<Resource
						icon={Tag}
						name="product-tags"
						options={{ label: 'PRODUCT_TAGS' }}
						list={ProductTagsData}
						edit={ProductTagEdit}
						create={ProductTagCreate}
						show={ProductTagShow}
					/>,

					<Resource
						icon={Category}
						name="product-sub-categories"
						options={{ label: 'PRODUCT_SUB_CATEGORIES' }}
						list={ProductSubCategoriesData}
						edit={ProductSubCategoryEdit}
						create={ProductSubCategoryCreate}
						show={ProductSubCategoryShow}
					/>,

					<Resource
						icon={Category}
						name="product-categories"
						options={{ label: 'PRODUCT_CATEGORIES' }}
						list={ProductCategoriesData}
						edit={ProductCategoryEdit}
						create={ProductCategoryCreate}
						show={ProductCategoryShow}
					/>,

					<Resource
						icon={Cart}
						name="products"
						options={{ label: 'PRODUCTS' }}
						list={ProductsData}
						edit={ProductEdit}
						create={ProductCreate}
						show={ProductShow}
					/>,

					<Resource
						icon={Event}
						name="events"
						options={{ label: 'EVENTS' }}
						list={EventsData}
						edit={EventEdit}
						create={EventCreate}
						show={EventShow}
					/>,

					<Resource
						icon={Cart}
						name="product-items"
						options={{ label: 'PRODUCT_ITEMS' }}
						edit={ProductItemEdit}
						create={ProductItemCreate}
					/>,

					<Resource
						icon={Star}
						name="products"
						options={{ label: 'PRODUCTS' }}
						list={ProductsData}
						edit={ProductEdit}
						create={ProductCreate}
						show={ProductShow}
					/>,

					<Resource
						icon={Notifications}
						name="notifications"
						options={{ label: 'NOTIFICATIONS' }}
						create={NotificationsForm}
					/>,

					<Resource
						icon={BabyChangingStation}
						name="kindergartens"
						options={{ label: 'KINDERGARTENS' }}
						list={KindergartensData}
						edit={KindergartenEdit}
						create={KindergartenCreate}
						show={KindergartenShow}
					/>,

					<Resource
						icon={BabyChangingStation}
						name="employees"
						options={{ label: 'EMPLOYEES' }}
						list={EmployeesData}
						edit={EmployeeEdit}
						create={EmployeeCreate}
						show={EmployeeShow}
					/>,

					<CustomRoutes>
						<Route path="/events/unhandled"
						       element={<EventsData resource="events"
						                            filter={{ segment: EEventSegment.UnHandled }}><></>
						       </EventsData>}/>
						<Route path="/events/future"
						       element={<EventsData resource="events" filter={{ segment: EEventSegment.Future }}><></>
						       </EventsData>}/>
						<Route path="/events/history"
						       element={<EventsData resource="events" filter={{ segment: EEventSegment.History }}><></>
						       </EventsData>}/>
						<Route path="/events/:id/product-items/create"
						       element={<ProductItemCreate resource="product-items"/>}/>
					</CustomRoutes>
				]}
			</Admin>
		</div>
	);
};

export default App;
