import * as React from 'react';
import { Datagrid, EmailField, NumberField, TextField } from 'react-admin';
import { PhoneField } from '../components/PhoneField';
import './EmployeeDatagrid.scss';

export const EmployeeDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick='edit' className='employees-table' optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
	        <NumberField source='id' label='#'/>
            <TextField source='fullName' label='FULL_NAME'/>
            <PhoneField source='phone' label='PHONE'/>
            <EmailField source='email' label='EMAIL'/>
            <TextField source='password' label='PASSWORD'/>
		</Datagrid>
	);
};
