import { GetListParams } from 'react-admin';
import { LazyLoadProperties } from '../interfaces/query.interface';
import { isNil } from 'lodash-es';
import { ESearchType } from '../enums';
import { format } from 'date-fns';

export const formatQueryParams = (params: GetListParams): LazyLoadProperties<any> => {
	const { page, perPage } = params.pagination;
	const { field, order } = params.sort;

	const query = params.filter?.q;
	delete params.filter?.q;

	const filterKeys = Object.keys(params.filter || {});

	const properties = {
		page,
		limit: perPage,
		sortFields: (field && order) ? [{ key: field, order: order === 'ASC' ? 1 : -1 }] : [],
		searchFields: filterKeys.filter(k => k !== 'search' && k !== 'q').map((key, i) => {
			if (key.indexOf('from_') === 0) {
				const field = key.split('_').slice(1).join('_');
				const toKey = `to_${field}`;

				if (isNil(params.filter[toKey])) {
					return {
						key: field,
						from: params.filter[key],
						type: ESearchType.BETWEEN
					};
				} else {
					if (filterKeys.indexOf(toKey) > i) {
						const fromValue = params.filter[key];
						const toValue = params.filter[toKey];

						return {
							key: field,
							from: fromValue,
							to: toValue,
							type: ESearchType.BETWEEN
						};
					}
				}
			} else if (key.indexOf('to_') === 0) {
				const field = key.split('_').slice(1).join('_');
				const fromKey = `from_${field}`;

				if (isNil(params.filter[fromKey])) {
					return {
						key: field,
						to: params.filter[key],
						type: ESearchType.BETWEEN
					};
				} else {
					if (filterKeys.indexOf(fromKey) > i) {
						const toValue = params.filter[key];
						const fromValue = params.filter[fromKey];

						return {
							key: field,
							from: fromValue,
							to: toValue,
							type: ESearchType.BETWEEN
						};
					}
				}
			} else if (key.indexOf('enum_') === 0) {
				return {
					key: key.split('_').slice(1).join('_'),
					value: params.filter[key],
					type: ESearchType.EQUALS
				};
			} else {
				return {
					key,
					value: params.filter[key],
					type: typeof params.filter[key] === 'string' ? ESearchType.CONTAINS : ESearchType.EQUALS
				};
			}

			return null;
		}).filter(v => !isNil(v))
	} as LazyLoadProperties<any>;

	if (query) {
		properties.search = query;
	}

	return properties;
};

export const dateFormatter = (date: number): string => {
	if (date && new Date(date)?.toString() !== 'Invalid Date') {
		return format(new Date(date), 'dd.MM.yyyy');
	}

	return '';
};
