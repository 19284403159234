import * as React from 'react';
import { Create, CreateProps, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const BulPostCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="title" label="TITLE"/>
				<TextInput multiline source="description" label="DESCRIPTION"/>
				<UploadImage source="imageUrl" label="IMAGE"/>
				<TextInput source="link" label="LINK" defaultValue={null}/>
			</SimpleForm>
		</Create>
	);
};
