import * as React from 'react';
import { EditButton, Identifier, Labeled, ReferenceField, TextField } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

import { AdUserClick } from './AdUserClick';

const adUserClickStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface AdUserClickListProps {
	ids?: Identifier[];
	data?: { [key: number]: AdUserClick };
	basePath?: string;
}

export const AdUserClickList = (props: AdUserClickListProps) => {
	const { ids, data } = props;
	const classes = adUserClickStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="ad-user-clicks"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="AD" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="adId" label="FORUM" reference="ads">
								<TextField source="title"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="USER" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="userId" reference="users" label="USER">
								<ReferenceField reference="parents" source="parentId">
									<TextField source="fullName"/>
								</ReferenceField>
								<TextField source="fullName"/>
							</ReferenceField>

						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
