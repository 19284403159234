import * as React from 'react';
import { AutocompleteInput, Create, CreateProps, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const MissingProductCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source='title' label='TITLE'/>
				<TextInput source='description' label='DESCRIPTION'/>
				<TextInput source='canBeFoundIn' label='CAN_BE_FOUND_IN'/>
				<UploadImage source='imageUrl' label='IMAGE'/>
				<ReferenceInput
                    label='PARENT'
                    source='parentId' reference='parents'
                    alwaysOn
                >
                    <AutocompleteInput label='PARENT' optionText='fullName'/>
                </ReferenceInput>
                
			</SimpleForm>
		</Create>
	);
};
