import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { ParentList } from './ParentList';
import { ParentDatagrid } from './ParentDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Parent } from './Parent';

export * from './Parent';
export * from './ParentCreate';
export * from './ParentDatagrid';
export * from './ParentEdit';
export * from './ParentList';
export * from './ParentShow';

const ParentsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'fullName', header: 'שם מלא' },
	{ key: 'user.phone', header: 'טלפון' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'children', header: 'ילדים' }
];

export const ParentsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Parent) => entity;

	const parentsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('הורים', exportFields, exportDataMapper)}
				{...props}
				filters={parentsFilter}
				bulkActionButtons={<ParentsBulkActionButtons/>}
				hasCreate={false}
				title="PARENTS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ParentList/> : <ParentDatagrid/>}
			</List>
		</Fragment>
	);
};
