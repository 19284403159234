import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { AdList } from './AdList';
import { AdDatagrid } from './AdDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Ad } from './Ad';

export * from './Ad';
export * from './AdCreate';
export * from './AdDatagrid';
export * from './AdEdit';
export * from './AdList';
export * from './AdShow';

const AdsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode='undoable'/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'title', header: 'כותרת' },
	{ key: 'startDate', header: 'תאריך התחלה' },
	{ key: 'finishDate', header: 'תאריך סיום' },
	{ key: 'link', header: 'קישור' },
	{ key: 'imageUrl', header: 'תמונה' },
	{ key: 'buttonText', header: 'טקסט כפתור' }
];

export const AdsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Ad) => entity;

	const adsFilter = [
		<TextInput label="SEARCH" source="q" alwaysOn/>,
		<DateInput placeholder="dd.mm.yyyy" source="from__createdAt" label="FROM_CREATED_AT"
		           alwaysOn/>,
		<DateInput placeholder="dd.mm.yyyy" source="to__createdAt" label="TO_CREATED_AT"
		           alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('מודעות', exportFields, exportDataMapper)}
				{...props}
				filters={adsFilter}
				bulkActionButtons={<AdsBulkActionButtons/>}
				title="ADS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <AdList/> : <AdDatagrid/>}
			</List>
		</Fragment>
	);
};
