import * as React from 'react';
import { ImageField, ImageInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { apiUrl, httpClient } from '../providers/data.provider';

const PreviewImage = ({ record, source }: any) => {
	if (typeof (record) == 'string') {
		record = {
			[source]: record
		};
	}
	return <ImageField record={record} source={source}/>;
};

export const UploadImage = ({ record, source, multiple, ...props }: any) => {
	const form = useFormContext();

	const onFileChange = async (files: any) => {
		if (files) {
			const formData = new FormData();

			if (multiple && files.length) {
				for (let i = 0; i < files.length; i++) {
					formData.append(`files`, files[i]);
				}
			} else {
				formData.append('file', files);
			}

			const { json } = await httpClient(`${apiUrl}/files/upload/${multiple ? 'multiple' : 'single'}`, {
				method: 'POST',
				headers: new Headers({ 'ContentType': 'multipart/form-data' }),
				body: formData
			});

			form.setValue(source, json.data);
		}
	};

	return <ImageInput {...props}
	                   source={source}
	                   multiple={multiple}
	                   onChange={onFileChange}
	>
		<PreviewImage source="src"/>
	</ImageInput>;
};
