import * as React from 'react';
import {
	BooleanField,
	DateField,
	EmailField,
	FunctionField,
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField
} from 'react-admin';
import { PriceField } from '../components/PriceField';
import { PhoneField } from '../components/PhoneField';
import { TranslateField } from '../components/TranslateField';

export const PurchaseShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="transactionId"
				           label="TRANSACTION_ID"/>
				<ReferenceField source="productId" reference="products" label="PRODUCT">
					<TextField source="title"/>
				</ReferenceField>

				<ReferenceField source="eventId" reference="events" label="EVENT">
					<FunctionField
						render={(record) => record.children?.length ? record.children.map(child => child.fullName).join(', ') : record.id}/>
				</ReferenceField>

				<TextField source="customer.personalId" label="PERSONAL_ID"/>
				<TextField source="customer.name" label="CUSTOMER_NAME"/>
				<TextField source="customer.childName" label="CUSTOMER_CHILD_NAME"/>
				<EmailField source="customer.email" label="EMAIL"/>
				<PhoneField source="customer" label="PHONE_NUMBER"/>
				<TextField source="customer.message" label="MESSAGE"/>
				<PriceField source="sum" label="SUM"/>
				<TextField source="address.addressStr" label="ADDRESS"/>
				<TranslateField source="status" label="STATUS"/>
				<BooleanField source="isSettled" label="IS_SETTLED"/>
				<TextField source="transactionToken" label="TRANSACTION_TOKEN"/>
			</SimpleShowLayout>
		</Show>
	);
};
