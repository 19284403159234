import * as React from 'react';
import { DateTimeInput, Edit, EditProps, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const AdEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode='undoable'>
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="title" validate={[required()]} label="TITLE"/>
				<UploadImage source="imageUrl" validate={[required()]} label="IMAGE"/>
				<DateTimeInput source="startDate" validate={[required()]} label="START_DATE"/>
				<DateTimeInput source="finishDate" validate={[required()]} label="FINISH_DATE"/>
				<TextInput type="url" source="link" label="LINK" defaultValue={null}/>
				<TextInput source="buttonText" label="BUTTON_TEXT" defaultValue={null}/>
			</SimpleForm>
		</Edit>
	);
};
