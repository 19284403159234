import * as React from 'react';
import { Edit, EditProps, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const ProductTagEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="name" label="NAME"/>
			</SimpleForm>
		</Edit>
	);
};
