import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { VendorList } from './VendorList';
import { VendorDatagrid } from './VendorDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Vendor } from './Vendor';

export * from './Vendor';
export * from './VendorCreate';
export * from './VendorDatagrid';
export * from './VendorEdit';
export * from './VendorList';
export * from './VendorShow';

const VendorsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' },
	{ key: 'user.phone', header: 'טלפון' },
	{ key: 'user.email', header: 'אימייל' },
	{ key: 'address.addressStr', header: 'מייל' },
	{ key: 'companyId', header: 'מ.ז.' },
	{ key: 'radiusInKM', header: 'רדיוס בק״מ' }
];

export const VendorsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Vendor) => entity;

	const vendorsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('ספקים', exportFields, exportDataMapper)}
				{...props}
				filters={vendorsFilter}
				bulkActionButtons={<VendorsBulkActionButtons/>}
				title="VENDORS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <VendorList/> : <VendorDatagrid/>}
			</List>
		</Fragment>
	);
};
