import * as React from 'react';
import { NumberField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, } from 'react-admin';

export const AdUserClickShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<ReferenceField source="adId" reference="ads" label="AD">
					<TextField source="title"/>
				</ReferenceField>

				<ReferenceField source="userId" reference="users" label="USER">
					<ReferenceField reference="parents" source="parentId">
						<TextField source="fullName"/>
					</ReferenceField>
					<TextField source="fullName"/>
				</ReferenceField>

			</SimpleShowLayout>
		</Show>
	);
};
