import * as React from 'react';
import {
	AutocompleteInput,
	Edit,
	EditProps,
	NumberInput,
	ReferenceInput,
	SimpleForm,
	TextInput,
	usePermissions,
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { EUserType } from '../enums';

export const KindergartenEdit = (props: EditProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="name" label="NAME"/>
				<GoogleAddressInput source="address" label="ADDRESS"/>
				{permissions !== EUserType.Employee ? <ReferenceInput
					label="CREATED_BY"
					source="createdById" reference="employees"
					alwaysOn
				>
					<AutocompleteInput label="CREATED_BY" optionText="fullName"/>
				</ReferenceInput> : null}

			</SimpleForm>
		</Edit>
	);
};
