import * as React from 'react';
import {
	DateField,
	EditButton,
	Identifier,
	Labeled,
	NumberField,
	ReferenceField,
	TextField,
	UrlField
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

import { Child } from './Child';
import { TranslateField } from '../components/TranslateField';

const childStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface ChildListProps {
	ids?: Identifier[];
	data?: { [key: number]: Child };
	basePath?: string;
}

export const ChildList = (props: ChildListProps) => {
	const { ids, data } = props;
	const classes = childStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="children"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="LOCATION_REMARKS" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="fullName" label="LOCATION_REMARKS"/>
						</Labeled>
						<Labeled label="PARENT" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="parentId" label="FORUM" reference="parents">
								<TextField source="fullName"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="PROFILE_IMAGE" sx={classes.cardContentRow}>
							<UrlField record={data[id]} source="profileImageUrl" label="PROFILE_IMAGE"/>
						</Labeled>
						<Labeled label="GENDER" sx={classes.cardContentRow}>
							<TranslateField record={data[id]} source="gender" label="GENDER"/>
						</Labeled>
						<Labeled  label="BIRTH_DATE" sx={classes.cardContentRow}>
							<DateField locales="he-IL" record={data[id]} source="birthDate" label="BIRTH_DATE"/>
						</Labeled>
						<Labeled label="NUMBER_OF_FRIENDS" sx={classes.cardContentRow}>
							<NumberField record={data[id]} source="numberOfFriends" label="NUMBER_OF_FRIENDS"/>
						</Labeled>
						<Labeled label="SHOE_SIZE" sx={classes.cardContentRow}>
							<NumberField source="shoeSize" label="SHOE_SIZE"/>
						</Labeled>
						<Labeled label="SHIRT_SIZE" sx={classes.cardContentRow}>
							<NumberField source="shirtSize" label="SHIRT_SIZE"/>
						</Labeled>
						<Labeled label="PANTS_SIZE" sx={classes.cardContentRow}>
							<NumberField source="pantsSize" label="PANTS_SIZE"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
