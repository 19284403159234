import * as React from 'react';
import { Datagrid, NumberField, ReferenceField, TextField } from 'react-admin';
import './AdUserClickDatagrid.scss';

export const AdUserClickDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="show" className="ad-user-clicks-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<ReferenceField source="adId" reference="ads" label="AD">
				<TextField source="title"/>
			</ReferenceField>

			<ReferenceField source="userId" reference="users" label="USER">
				<ReferenceField reference="parents" source="parentId">
					<TextField source="fullName"/>
				</ReferenceField>
				<TextField source="fullName"/>
			</ReferenceField>
		</Datagrid>
	);
};
