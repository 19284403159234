import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, } from 'react-admin';
import { KindergartenList } from './KindergartenList';
import { KindergartenDatagrid } from './KindergartenDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Kindergarten } from './Kindergarten';

export * from './Kindergarten';
export * from './KindergartenCreate';
export * from './KindergartenDatagrid';
export * from './KindergartenEdit';
export * from './KindergartenList';
export * from './KindergartenShow';

const KindergartensBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode='pessimistic'/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' },
	{ key: 'id', header: '#' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'id', header: '#' },
	{ key: 'createdById', header: 'נוצר ע״י' },
];

export const KindergartensData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Kindergarten) => entity;

	const kindergartensFilter = [
		<DateInput source='from__createdAt' label='FROM_CREATED_AT' alwaysOn/>,
		<DateInput source='to__createdAt' label='TO_CREATED_AT' alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('גני ילדים', exportFields, exportDataMapper)}
				{...props}
				filters={kindergartensFilter}
				bulkActionButtons={<KindergartensBulkActionButtons/>}
				title='KINDERGARTENS'
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <KindergartenList/> : <KindergartenDatagrid/>}
			</List>
		</Fragment>
	);
};
