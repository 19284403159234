export const hebrewMessages = {
	PROFILE_IMAGE: 'תמונת פרופיל',
	NAME: 'שם',
	CONTENT: 'תוכן',
	PRICE: 'מחיר',
	SEARCH: 'חיפוש',
	IS_READ: 'נצפה',
	DATE: 'תאריך',
	DASHBOARD_WELCOME: 'ברוכים הבאים לפאנל הניהול',
	DASHBOARD: 'דשבורד',
	MONTHLY_REVENUE: 'הכנסה חודשית',
	NEW_ORDERS: 'הזמנות חדשות',
	MONTHLY_PURCHASES: 'רכישות',
	FROM_DATE: 'מתאריך',
	TO_DATE: 'עד תאריך',
	GENDER: 'מין',
	FULL_NAME: 'שם מלא',
	HAS_CREDIT: 'יש קרדיט',
	BUDGET: 'תקציב',
	TOTAL: 'סה״כ',
	FROM_CREATED_AT: 'שנוצרו מ-',
	TO_CREATED_AT: 'שנוצרו עד',
	MISSING_PRODUCT: 'מוצר חסר',
	MISSING_PRODUCTS: 'מוצרים חסרים',

	AD_USER_CLICK: 'שימוש במודעה',
	AD_USER_CLICKS: 'שימושים במודעות',

	EMPLOYEE: 'עובד בול',
	EMPLOYEES: 'עובדי בול',

	KINDERGARTEN: 'גן ילדים',
	KINDERGARTENS: 'גני ילדים',

	INVITATION: 'תצוגת הזמנה',
	INVITATIONS: 'תצוגת הזמנות',

	BUL_POST: 'פוסט על בול',
	BUL_POSTS: 'פוסטים על בול',

	PURCHASE: 'רכישה',
	PURCHASES: 'רכישות',

	SERVICE_PROVIDER_LEAD: 'ליד נותן שירות',
	SERVICE_PROVIDER_LEADS: 'לידים נותני שירות',

	SERVICE_PROVIDER: 'נותן שירות',
	SERVICE_PROVIDERS: 'נותני שירות',

	SERVICE_PROVIDER_CATEGORY: 'קטגוריית נותן שירות',
	SERVICE_PROVIDER_CATEGORIES: 'קטגוריות נותני שירות',

	CHILD: 'ילד',
	CHILDREN: 'ילדים',

	PARENT: 'הורה',
	PARENTS: 'הורים',
	CREATED_AT: 'נוצר ב-',
	VENDOR: 'ספק',
	VENDORS: 'ספקים',
	IS_BLOCKED: 'חסום?',
	PRODUCT_TAG: 'תגית מוצר',
	TAG: 'תגית',
	PRODUCT_TAGS: 'תגיות מוצרים',
	LINK: 'קישור',
	PRODUCT_SUB_CATEGORY: 'תת קטגוריית מוצר',
	PRODUCT_SUB_CATEGORIES: 'תתי קטגוריות מוצרים',

	PRODUCT_CATEGORY: 'קטגוריית מוצר',
	PRODUCT_CATEGORIES: 'קטגוריות מוצרים',

	EVENT: 'אירוע',
	EVENTS: 'אירועים',

	PRODUCT: 'מוצר',
	PRODUCTS: 'מוצרים',
	IMAGE: 'תמונה',
	DESCRIPTION: 'תיאור',
	Male: 'זכר',
	Female: 'נקבה',
	FULL_IMAGE: 'תמונה מלאה',
	SEND_NOTIFICATION_TO_CLIENTS: 'שליחת התראה לכל המשתמשים',
	NOTIFICATIONS: 'התראות',
	TITLE: 'כותרת',
	BIRTH_DATE: 'תאריך לידה',
	NUMBER_OF_FRIENDS: 'מס׳ חברים',
	ADDRESS: 'כתובת',
	LOCATION_REMARKS: 'הערות מיקום',
	BACKGROUND_IMAGE: 'תמונת רקע',
	TOTAL_SUM: 'סכום כולל',
	NUMBER_OF_PARTICIPANTS: 'מס׳ משתתפים',
	DID_SKIP_PROVIDERS: 'דילג על נותני שירות?',
	ALL_PURCHASES_HANDLED: 'טופל באופן מלא?',
	TOKEN: 'טוקן',
	SKU: 'מק״ט',
	SHORT_DESCRIPTION: 'תיאור קצר',
	GALLERY: 'גלריה',
	CATEGORY: 'קטגוריה',
	SUB_CATEGORY: 'תת קטגוריה',
	TAGS: 'תגיות',
	GIFT_TYPE: 'סוג מתנה',
	IS_IN_STOCK: 'במלאי?',
	TRANSACTION_ID: 'מס׳ עסקה',
	PERSONAL_ID: 'ת.ז. מוזמן',
	CUSTOMER_NAME: 'שם מוזמן',
	CUSTOMER_CHILD_NAME: 'שם ילד מוזמן',
	EMAIL: 'אימייל',
	PHONE_NUMBER: 'טלפון מוזמן',
	MESSAGE: 'ברכת מוזמן',
	SUM: 'סכום',
	DELIVERY_SUM: 'סכום שילוח',
	STATUS: 'סטטוס',
	IS_SETTLED: 'נסלק?',
	TRANSACTION_TOKEN: 'טוקן עסקה',
	PHONE: 'טלפון',
	FEATURED_IMAGE: 'תמונה ראשית',
	COMPANY_ID: 'מ.ז.',
	RADIUS_IN_KM: 'רדיוס בק״מ',
	Admin: 'אדמין',
	Regular: 'רגיל',
	EQUALS: 'שווה',
	CONTAINS: 'מכיל',
	BETWEEN: 'בין',
	TEXT_COLOR: 'צבע טקסט',
	CTA_BACKGROUND_COLOR: 'רקע כפתור',
	CTA_TEXT_COLOR: 'צבע טקסט בכפתור',
	Approved: 'מאושר',
	SMS: 'אסמס',
	Email: 'מייל',
	All: 'הכל',
	AdminPanel: 'פאנל ניהול',
	App: 'אפליקציה',
	Web: 'אתר',
	Purchased: 'נרכש',
	Out_Of_Stock: 'אין במלאי',
	OutOfStock: 'אין במלאי',
	InDelivery: 'במשלוח',
	Completed: 'הסתיים',
	Personal: 'אישי',
	Shared: 'שיתופי',
	Bit: 'ביט',
	NotPurchased: 'לא נרכש',
	UnHandled: 'לא טופל',
	Canceled: 'בוטל',
	InProgress: 'בעבודה',
	Handled: 'טופל',
	CLOSE: 'סגירה',
	SETTLE: 'לסלוק',
	EDIT: 'עריכה',
	PASSWORD: 'סיסמה',
	UNHANDLED_EVENTS: 'דורשים טיפול',
	FUTURE_EVENTS: 'עתידיים',
	HISTORY_EVENTS: 'היסטוריה',
	PRODUCT_ITEMS: 'מוצרים',
	PURCHASED: 'נרכש?',
	TOTAL_PURCHASED_SUM: 'סה״כ סכום ששולם',
	NUMBER_OF_PURCHASES: 'מס׳ הרכישות',
	DETAILS: 'נתונים',
	NOTE: 'הערות',
	TOTAL_EVENTS: 'סה״כ אירועים',
	TOTAL_PARENTS: 'סה״כ הורים',
	TOTAL_PRODUCTS: 'סה״כ מוצרים',
	TOTAL_PURCHASES_SUM: 'סה״כ סכום רכישות',
	TOTAL_PURCHASES: 'סה״כ מס׳ רכישות',
	ADD_PRODUCT_ITEM: 'הוספת מוצר',
	CREATED_BY: 'נוצר ע״י',
	SHOE_SIZE: 'מידת נעליים',
	SHIRT_SIZE: 'מידת חולצה',
	PANTS_SIZE: 'מידת מכנסיים',
	FROM_BIRTH_DATE_DAY: 'מיום',
	FROM_BIRTH_DATE_MONTH: 'מחודש',
	TO_BIRTH_DATE_DAY: 'עד יום',
	TO_BIRTH_DATE_MONTH: 'עד חודש',
	START_DATE: 'מתאריך',
	FINISH_DATE: 'מתאריך',
	UPDATED_AT: 'עודכן בתאריך',
	BUTTON_TEXT: 'טקסט כפתור',
	ADS: 'מודעות',
	AD: 'מודעה',
	USER: 'משתמש',
	USERS: 'משתמשים',
	CAN_BE_FOUND_IN: 'ניתן למצוא ב...',
	PARENT_PHONE: 'טלפון הורה',
	general: {
		error: 'שגיאה כללית'
	},
	message: {
		error: 'שגיאה כללית'
	},
	error: {
		general: 'שגיאה כללית',
		request: {
			invalid_req_params: 'נשלחו פרטים לא תקינים'
		},
		files: {
			empty: 'לא נשלח קובץ',
			unsupported_ext: 'סיומת קובץ לא נתמכת'
		},
		auth: {
			wrong_credentials: 'פרטי הזדהות לא תקינים',
			not_admin: 'אין הרשאות אדמין',
			not_client: 'אין הרשאות לקוח',
			failed_authenticating_jwt: 'משתמש לא מזוהה',
			not_registered: 'משתמש לא קיים',
			invalid_token: 'משתמש פג תוקף',
			no_registered: 'לא רשום'
		}
	},
	resources: {
	'missing-product': { name: 'מוצר חסר', },
	'missing-products': { name: 'מוצרים חסרים', },

	'ad-user-click': { name: 'שימוש במודעה', },
	'ad-user-clicks': { name: 'שימושים במודעות', },

	'employee': { name: 'עובד בול', },
	'employees': { name: 'עובדי בול', },

		'kindergarten': { name: 'גן ילדים', },
		'kindergartens': { name: 'גני ילדים', },

		'invitation': { name: 'תצוגת הזמנה' },
		'invitations': { name: 'תצוגת הזמנות' },

		'bul-post': { name: 'פוסט על בול' },
		'bul-posts': { name: 'פוסטים על בול' },

		'purchase': { name: 'רכישה' },
		'purchases': { name: 'רכישות' },

		'service-provider-lead': { name: 'ליד נותן שירות' },
		'service-provider-leads': { name: 'לידים נותני שירות' },

		'service-provider': { name: 'נותן שירות' },
		'service-providers': { name: 'נותני שירות' },

		'service-provider-category': { name: 'קטגוריית נותן שירות' },
		'service-provider-categories': { name: 'קטגוריות נותני שירות' },

		'child': { name: 'ילד' },
		'children': { name: 'ילדים' },

		'parent': { name: 'הורה' },
		'parents': { name: 'הורים' },

		'vendor': { name: 'ספק' },
		'vendors': { name: 'ספקים' },

		'product-tag': { name: 'תגית מוצר' },
		'product-tags': { name: 'תגיות מוצרים' },

		'product-sub-category': { name: 'תת קטגוריית מוצר' },
		'product-sub-categories': { name: 'תתי קטגוריות מוצרים' },

		'product-category': { name: 'קטגוריית מוצר' },
		'product-categories': { name: 'קטגוריות מוצרים' },

		'product': { name: 'מוצר' },
		'products': { name: 'מוצרים' },
		'event': { name: 'אירוע' },
		'events': { name: 'אירועים' }
	},
	ra: {
		saved_queries: {
			label: 'חיפושים שמורים',
			query_name: 'כותרת חיפוש',
			new_label: 'שמירת חיפוש',
			new_dialog_title: 'שמירת חיפוש בתור',
			remove_label: 'מחיקת חיפוש',
			remove_label_with_name: 'מחיקת חיפוש "%{name}"',
			remove_dialog_title: 'למחוק את החיפוש?',
			remove_message:
				'האם אתה בטוח שברצונך למחוק חיפוש מרשימת החיפושים?',
			help: 'חפש ושמור את החיפוש לפעם הבאה'
		},
		action: {
			clear_input_value: 'נקה',
			delete: 'מחק',
			unselect: 'בטל בחירה',
			sort: 'מיון',
			show: 'הצג',
			undo: 'שחזר',
			confirm: 'אישור',
			bulk_actions: 'נבחרו %{smart_count} פריטים',
			list: 'רשימה',
			export: 'ייצוא',
			save: 'שמור',
			create: 'צור',
			edit: 'ערוך',
			cancel: 'ביטול',
			refresh: 'רענן',
			add_filter: 'הוסף סינון',
			remove_filter: 'הסר סינון',
			back: 'חזור',
			add: 'הוספה',
			remove: 'הסרה'
		},
		boolean: {
			true: 'כן',
			false: 'לא'
		},
		page: {
			list: 'רשימת %{name}',
			edit: '%{name} #%{id}',
			show: '%{name} #%{id}',
			empty: 'לא נמצאו תוצאות',
			invite: 'מקווים שממש בקרוב יגיע לפה מידע...',
			create: 'צור %{name}',
			delete: 'מחק %{name} #%{id}',
			dashboard: 'דשבורד',
			not_found: 'לא קיים',
			error: 'שגיאה'
		},
		input: {
			references: {
				single_missing: 'חסר'
			},
			file: {
				upload_several:
					'גרור מספר קבצים להעלאה או לחץ לבחור אחד.',
				upload_single: 'גרור קובץ להעלאה או לחץ לבחור אחד.'
			},
			image: {
				upload_several:
					'גרור מספר תמונות להעלאה או לחץ לבחור אחת.',
				upload_single:
					'גרור תמונה להעלאה או לחץ לבחור אחת.'
			}
		},
		message: {
			error: 'אופס.. קרתה שגיאה',
			invalid_form: 'טופס לא תקין',
			yes: 'כן',
			no: 'לא',
			are_you_sure: 'האם את/ה בטוח/ה ?',
			about: 'אודות',
			not_found:
				'הכנסת כתובת שגויה או לחצת על קישור לא תקין',
			delete_title: 'מחיקה',
			delete_content: 'האם אתה בטוח שברצונך למחוק?',
			bulk_delete_title: 'שים לב!',
			bulk_delete_content: 'האם אתה בטוח שברצונך למחוק את כל המידע הזה?'
		},
		navigation: {
			no_results: 'לא נמצאו תוצאות',
			page_rows_per_page: 'מס׳ שורות בעמוד',
			skip_nav: 'דילוג',
			page_out_of_boundaries: 'עמוד %{page} מחוץ לתחום',
			page_out_from_end: 'לא ניתן להמשיך מעבר לעמוד האחרון',
			page_out_from_begin: 'לא ניתן לגשת לפני העמוד הראשון',
			page_range_info: '%{offsetBegin}-%{offsetEnd} מתוך %{total}',
			next: 'הבא',
			prev: 'הקודם'
		},
		auth: {
			username: 'שם משתמש',
			password: 'סיסמא',
			sign_in: 'כניסה',
			sign_in_error: 'הכניסה נכשלה, אנא נסו שנית',
			logout: 'התנתקות',
			auth_check_error: 'הזדהות לא הצליחה'
		},
		notification: {
			data_provider_error: 'תקלה בתקשורת מידע',
			updated: 'פריט עודכן בהצלחה',
			created: 'פריט נוצר בהצלחה',
			deleted: 'פריט נמחק בהצלחה',
			item_doesnt_exist: 'פריט לא קיים',
			http_error: 'שגיאה בהתקשרות מול השרת'
		},
		validation: {
			required: 'שדה חובה',
			minLength: 'חייב להכיל לפחות %{min} תווים',
			maxLength: 'יכול להכיל לכל היותר %{max} תווים',
			minValue: 'חייב להיות לפחות %{min}',
			maxValue: 'חייב להיות %{max} או פחות',
			number: 'חייב להיות מספר',
			email: 'כתובת דוא"ל לא תקנית'
		}
	}
};
