import * as React from 'react';
import { ImageField, NumberField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

export const InvitationShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<TextField source="name" label="NAME"/>
				<ImageField source="imageUrl" label="IMAGE"/>
				<TextField source="textColor" label="TEXT_COLOR"/>
				<TextField source="ctaBackgroundColor" label="CTA_BACKGROUND_COLOR"/>
				<TextField source="ctaTextColor" label="CTA_TEXT_COLOR"/>
			</SimpleShowLayout>
		</Show>
	);
};
