import * as React from 'react';
import { Datagrid, DateField, ImageField, NumberField, ReferenceField, TextField } from 'react-admin';
import './ProductSubCategoryDatagrid.scss';

export const ProductSubCategoryDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="product-sub-categories-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="name" label="NAME"/>
			<ImageField source="imageUrl" label="IMAGE"/>
			<ReferenceField source="categoryId" reference="product-categories" label="CATEGORY">
				<TextField source="name"/>
			</ReferenceField>

		</Datagrid>
	);
};
