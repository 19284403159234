import * as React from 'react';
import { DateField, NumberField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

export const ServiceProviderLeadShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<ReferenceField source="serviceProviderId"
				                reference="service-providers"
				                label="SERVICE_PROVIDER">
					<TextField source="name"/>
				</ReferenceField>

				<ReferenceField source="eventId" reference="events" label="EVENT">
					<TextField source="id"/>
				</ReferenceField>

			</SimpleShowLayout>
		</Show>
	);
};
