import * as React from 'react';
import {
	BooleanInput,
	ChipField,
	Edit,
	EditProps,
	NumberInput,
	ReferenceArrayField,
	SimpleForm,
	SingleFieldList,
	TextInput
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';

export const ParentEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="fullName" label="FULL_NAME"/>
				<TextInput source="user.phone" label="PHONE" disabled/>
				<GoogleAddressInput source="address.addressStr" label="ADDRESS"/>
				<BooleanInput source="isBlocked" label="IS_BLOCKED"/>
				<ReferenceArrayField source="childrenIds" label="CHILDREN" reference="children">
					<SingleFieldList>
						<ChipField source="fullName"/>
					</SingleFieldList>
				</ReferenceArrayField>
			</SimpleForm>
		</Edit>
	);
};
