import * as React from 'react';
import { Edit, EditProps, NumberInput, PasswordInput, required, SimpleForm, TextInput } from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';

export const VendorEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="name" label="NAME"/>
				<TextInput source="user.phone.phone" label="PHONE" validate={required()}/>
				<TextInput source="user.email" label="EMAIL" validate={required()}/>
				<GoogleAddressInput source="address" label="ADDRESS"/>
				<TextInput source="companyId" label="COMPANY_ID"/>
				<NumberInput source="radiusInKM" label="RADIUS_IN_KM"/>
				<PasswordInput source="user.password" label="PASSWORD"/>
				<TextInput source="user.fullName" label="FULL_NAME" validate={required()}/>
			</SimpleForm>
		</Edit>
	);
};
