import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslate } from 'react-admin';
import { addDays, format, subDays } from 'date-fns';
import { Purchase } from '../../purchase';
import { dateFormatter } from '../../util/Formatters';

const referenceDate = new Date();
const lastDay = referenceDate;
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(referenceDate, 30);

const aggregatePurchasesByDay = (purchases: Purchase[]): { [key: string]: number } =>
	purchases
		.reduce((acc, curr) => {
			const day = format(new Date(curr._createdAt), 'dd.MM.yyyy');
			if (!acc[day]) {
				acc[day] = 0;
			}
			acc[day] += curr.sum || 0;
			return acc;
		}, {} as { [key: string]: number });

const getRevenuePerDay = (purchases: Purchase[]): TotalByDay[] => {
	const daysWithRevenue = aggregatePurchasesByDay(purchases);
	return lastMonthDays.map(date => ({
		date: new Date(date).getTime(),
		total: daysWithRevenue[format(date, 'dd.MM.yyyy')] || 0
	}));
};

const PurchasesChart = (props: { purchases?: Purchase[] }) => {
	const { purchases } = props;
	const translate = useTranslate();
	if (!purchases) return null;

	return (
		<Card>
			<CardHeader title={translate('MONTHLY_PURCHASES')}/>
			<CardContent>
				<div style={{ width: '100%', height: 300 }}>
					<ResponsiveContainer>
						<AreaChart data={getRevenuePerDay(purchases)}>
							<defs>
								<linearGradient
									id="colorUv"
									x1="0"
									y1="0"
									x2="0"
									y2="1"
								>
									<stop
										offset="5%"
										stopColor="#8884d8"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="#8884d8"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<XAxis
								dataKey="date"
								name="Date"
								type="number"
								scale="time"
								domain={[
									addDays(aMonthAgo, 1).getTime(),
									referenceDate.getTime()
								]}
								tickFormatter={dateFormatter}
							/>
							<YAxis dataKey="total" name="Revenue" unit="₪"/>
							<CartesianGrid strokeDasharray="3 3"/>
							<Tooltip
								cursor={{ strokeDasharray: '3 3' }}
								formatter={value =>
									new Intl.NumberFormat(undefined, {
										style: 'currency',
										currency: 'ILS'
									}).format(value as any)
								}
								labelFormatter={(label: any) =>
									dateFormatter(label)
								}
							/>
							<Area
								type="monotone"
								dataKey="total"
								stroke="#8884d8"
								strokeWidth={2}
								fill="url(#colorUv)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</CardContent>
		</Card>
	);
};

interface TotalByDay {
	date: number;
	total: number;
}

export default PurchasesChart;
