import * as React from 'react';
import { Datagrid, DateField, EmailField, NumberField, TextField } from 'react-admin';
import { PhoneField } from '../components/PhoneField';
import './VendorDatagrid.scss';

export const VendorDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="vendors-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="name" label="NAME"/>
			<PhoneField source="user.phone" label="PHONE"/>
			<EmailField source="user.email" label="EMAIL"/>
			<TextField source="address.addressStr" label="ADDRESS"/>
			<TextField source="companyId" label="COMPANY_ID"/>
			<NumberField source="radiusInKM" label="RADIUS_IN_KM"/>
		</Datagrid>
	);
};
