import React from 'react';
import { Login, LoginForm } from 'react-admin';

export const CustomLoginPage = () => (
	<Login
		className="CustomLoginPage"
		title={'בול | פאנל ניהול'}
		backgroundImage="assets/login-background.jpg"
	>
		<LoginForm>
		</LoginForm>
	</Login>
);
