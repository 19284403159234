import * as React from 'react';
import {
	ChipField,
	DateField,
	NumberField,
	ReferenceArrayField,
	Show,
	ShowProps,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	usePermissions
} from 'react-admin';
import { PhoneField } from '../components/PhoneField';
import { EUserType } from '../enums';

export const ParentShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={permissions === EUserType.Admin ? undefined : false}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="fullName" label="FULL_NAME"/>
				<PhoneField source="user.phone" label="PHONE"/>
				<TextField source="address.addressStr" label="ADDRESS"/>
				<ReferenceArrayField source="childrenIds" label="CHILDREN"
				                     reference="children">
					<SingleFieldList>
						<ChipField source="fullName"/>
					</SingleFieldList>
				</ReferenceArrayField> </SimpleShowLayout>
		</Show>
	);
};
