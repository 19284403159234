import * as React from 'react';
import {
	ChipField,
	EditButton,
	Identifier,
	Labeled,
	ReferenceArrayField,
	SingleFieldList,
	TextField
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Parent } from './Parent';
import { PhoneField } from '../components/PhoneField';

const parentStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface ParentListProps {
	ids?: Identifier[];
	data?: { [key: number]: Parent };
	basePath?: string;
}

export const ParentList = (props: ParentListProps) => {
	const { ids, data } = props;
	const classes = parentStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="parents"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="FULL_NAME" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="fullName" label="FULL_NAME"/>
						</Labeled>
						<Labeled label="PHONE" sx={classes.cardContentRow}>
							<PhoneField record={data[id]} source="user.phone" label="PHONE"/>
						</Labeled>
						<Labeled label="ADDRESS" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="address.addressStr" label="ADDRESS"/>
						</Labeled>
						<Labeled label="CHILDREN" sx={classes.cardContentRow}>
							<ReferenceArrayField source="childrenIds" label="CHILDREN"
							                     reference="children">
								<SingleFieldList>
									<ChipField source="fullName"/>
								</SingleFieldList>
							</ReferenceArrayField>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
