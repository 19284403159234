import * as React from 'react';
import { Datagrid, DateField, NumberField, ReferenceField, TextField } from 'react-admin';
import './ServiceProviderLeadDatagrid.scss';

export const ServiceProviderLeadDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="service-provider-leads-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<ReferenceField source="serviceProviderId"
			                reference="service-providers"
			                label="SERVICE_PROVIDER">
				<TextField source="name"/>
			</ReferenceField>

			<ReferenceField source="eventId" reference="events" label="EVENT">
				<TextField source="id"/>
			</ReferenceField>
		</Datagrid>
	);
};
