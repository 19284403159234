import * as React from 'react';
import { EditButton, Identifier, ImageField, Labeled, NumberField, ReferenceField, TextField } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

import { ServiceProvider } from './ServiceProvider';
import { PhoneField } from '../components/PhoneField';

const serviceProviderStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface ServiceProviderListProps {
	ids?: Identifier[];
	data?: { [key: number]: ServiceProvider };
	basePath?: string;
}

export const ServiceProviderList = (props: ServiceProviderListProps) => {
	const { ids, data } = props;
	const classes = serviceProviderStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="service-providers"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="NAME" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="name" label="NAME"/>
						</Labeled>
						<Labeled label="PHONE" sx={classes.cardContentRow}>
							<PhoneField record={data[id]} source="user.phone" label="PHONE"/>
						</Labeled>
						<Labeled label="ADDRESS" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="address.addressStr" label="ADDRESS"/>
						</Labeled>
						<Labeled label="DESCRIPTION" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="description" label="DESCRIPTION"/>
						</Labeled>
						<Labeled label="FEATURED_IMAGE" sx={classes.cardContentRow}>
							<ImageField record={data[id]} source="featuredImageUrl" label="FEATURED_IMAGE"/>
						</Labeled>
						<Labeled label="CATEGORY" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="categoryId" label="FORUM"
							                reference="service-provider-categories">
								<TextField source="name"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="COMPANY_ID" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="companyId" label="COMPANY_ID"/>
						</Labeled>
						<Labeled label="RADIUS_IN_KM" sx={classes.cardContentRow}>
							<NumberField record={data[id]} source="radiusInKM" label="RADIUS_IN_KM"/>
						</Labeled>
						<Labeled label="TITLE" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="title" label="TITLE"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
