import * as React from 'react';
import {
	AutocompleteInput,
	DateInput,
	Edit,
	EditProps,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput
} from 'react-admin';
import { EGender } from '../enums';

export const ChildEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="fullName" label="LOCATION_REMARKS"/>
				<ReferenceInput
					label="PARENT"
					source="parentId" reference="parents"
					alwaysOn
				>
					<AutocompleteInput label="PARENT" optionText="fullName"/>
				</ReferenceInput>

				<TextInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<SelectInput source="gender" label="GENDER" choices={Object.keys(EGender).map(key => ({
					id: key,
					name: EGender[key]
				}))}/>
				<DateInput source="birthDate" label="DATE"/>
				<NumberInput source="numberOfFriends" label="NUMBER_OF_FRIENDS"/>
				<NumberInput source="shoeSize" label="SHOE_SIZE"/>
				<NumberInput source="shirtSize" label="SHIRT_SIZE"/>
				<NumberInput source="pantsSize" label="PANTS_SIZE"/>
			</SimpleForm>
		</Edit>
	);
};
