import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { DateInput, List, ListProps, TextInput } from 'react-admin';
import { PurchaseList } from './PurchaseList';
import { PurchaseDatagrid } from './PurchaseDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Purchase } from './Purchase';

export * from './Purchase';
export * from './PurchaseCreate';
export * from './PurchaseDatagrid';
export * from './PurchaseEdit';
export * from './PurchaseList';
export * from './PurchaseShow';

// const PurchasesBulkActionButtons = (props: BulkActionProps) => (
// 	<Fragment>
// 		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
// 	</Fragment>
// );

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'transactionId', header: 'מס׳ עסקה' },
	{ key: 'productId', header: 'מוצר' },
	{ key: 'eventId', header: 'אירוע' },
	{ key: 'customer.personalId', header: 'ת.ז. מוזמן' },
	{ key: 'customer.name', header: 'שם מוזמן' },
	{ key: 'customer.childName', header: 'שם ילד מוזמן' },
	{ key: 'customer.email', header: 'אימייל מוזמן' },
	{ key: 'customer.phone', header: 'טלפון מוזמן' },
	{ key: 'customer.message', header: 'ברכת מוזמן' },
	{ key: 'sum', header: 'סכום' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'status', header: 'סטטוס' },
	{ key: 'isSettled', header: 'נסלק?' },
	{ key: 'transactionToken', header: 'טוקן עסקה' }
];

export const PurchasesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Purchase) => entity;

	const purchasesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('רכישות', exportFields, exportDataMapper)}
				{...props}
				filters={purchasesFilter}
				bulkActionButtons={false}
				title="PURCHASES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <PurchaseList/> : <PurchaseDatagrid/>}
			</List>
		</Fragment>
	);
};
