import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const DayMonthInput = (props) => {
	const { field: {value, onChange, name} } = useInput(props);
	const translate = useTranslate()

	const handleChange = (type) => (event) => {
		const updatedValue = { ...value, [type]: event.target.value };
		onChange(updatedValue);
	};

	const days = Array(31).fill(0).map((_, i) => i + 1);
	const months = Array(12).fill(0).map((_, i) => i + 1);

	return (
		<div>
			<FormControl>
				<InputLabel htmlFor={`${name}-day`}>{translate(props.dayLabel)}</InputLabel>
				<Select
					value={value?.day || ''}
					onChange={handleChange('day')}
					inputProps={{
						name: `${name}-day`,
						id: `${name}-day`,
					}}
				>
					{days.map((day) => (
						<MenuItem key={day} value={day}>
							{day}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl>
				<InputLabel htmlFor={`${name}-month`}>{translate(props.monthLabel)}</InputLabel>
				<Select
					value={value?.month || ''}
					onChange={handleChange('month')}
					inputProps={{
						name: `${name}-month`,
						id: `${name}-month`,
					}}
				>
					{months.map((month) => (
						<MenuItem key={month} value={month}>
							{month}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default DayMonthInput;
