import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { ProductTagList } from './ProductTagList';
import { ProductTagDatagrid } from './ProductTagDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { ProductTag } from './ProductTag';

export * from './ProductTag';
export * from './ProductTagCreate';
export * from './ProductTagDatagrid';
export * from './ProductTagEdit';
export * from './ProductTagList';
export * from './ProductTagShow';

const ProductTagsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' }
];

export const ProductTagsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: ProductTag) => entity;

	const productTagsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('תגיות מוצרים', exportFields, exportDataMapper)}
				{...props}
				filters={productTagsFilter}
				bulkActionButtons={<ProductTagsBulkActionButtons/>}
				title="PRODUCT_TAGS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ProductTagList/> : <ProductTagDatagrid/>}
			</List>
		</Fragment>
	);
};
