import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps } from 'react-admin';
import { ChildList } from './ChildList';
import { ChildDatagrid } from './ChildDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Child } from './Child';
import DayMonthInput from '../components/DayMonthInput';

export * from './Child';
export * from './ChildCreate';
export * from './ChildDatagrid';
export * from './ChildEdit';
export * from './ChildList';
export * from './ChildShow';

const ChildrenBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'fullName', header: 'שם מלא' },
	{ key: 'parentId', header: 'הורה' },
	{ key: 'profileImageUrl', header: 'תמונת פרופיל' },
	{ key: 'gender', header: 'מין' },
	{ key: 'birthDate', header: 'תאריך לידה' },
	{ key: 'numberOfFriends', header: 'מס׳ חברים' }
];

export const ChildrenData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Child) => entity;

	const childrenFilter = [
		<DayMonthInput source="from_birthDate" dayLabel="FROM_BIRTH_DATE_DAY" monthLabel="FROM_BIRTH_DATE_MONTH" alwaysOn/>,
		<DayMonthInput source="to_birthDate" dayLabel="TO_BIRTH_DATE_DAY" monthLabel="TO_BIRTH_DATE_MONTH" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,

	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('ילדים', exportFields, exportDataMapper)}
				{...props}
				filters={childrenFilter}
				bulkActionButtons={<ChildrenBulkActionButtons/>}
				title="CHILDREN"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ChildList/> : <ChildDatagrid/>}
			</List>
		</Fragment>
	);
};
