import * as React from 'react';
import {
	BooleanField,
	ChipField,
	Datagrid,
	DateField,
	ImageField,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	SingleFieldList,
	TextField,
	usePermissions
} from 'react-admin';
import './ProductDatagrid.scss';
import { TranslateField } from '../components/TranslateField';
import { EUserType } from '../enums';

export const ProductDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="products-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}
		>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="sku" label="SKU"/>
			<TextField source="title" label="TITLE"/>
			<TextField source="description" label="DESCRIPTION"/>
			<TextField source="shortDescription" label="SHORT_DESCRIPTION"/>
			<ImageField source="mainImageUrl" label="IMAGE"/>
			{permissions === EUserType.Admin &&
                <ReferenceField source="vendorId" reference="vendors" label="VENDOR">
                    <TextField source="name"/>
                </ReferenceField>
			}

			<ReferenceField source="categoryId" reference="product-categories" label="CATEGORY">
				<TextField source="name"/>
			</ReferenceField>

			<ReferenceField source="subCategoryId" reference="product-sub-categories" label="SUB_CATEGORY">
				<TextField source="name"/>
			</ReferenceField>

			<ReferenceArrayField source="tagsIds" label="TAGS"
			                     reference="product-tags">
				<SingleFieldList>
					<ChipField source="name"/>
				</SingleFieldList>
			</ReferenceArrayField>
			<TranslateField source="giftType" label="GIFT_TYPE"/>
			<NumberField source="price" label="PRICE"/>
			<BooleanField source="isInStock" label="IS_IN_STOCK"/>
		</Datagrid>
	);
};
