import * as React from 'react';
import {
	DateField,
	ImageField,
	NumberField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	usePermissions
} from 'react-admin';
import { EUserType } from '../enums';

export const ProductCategoryShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={permissions === EUserType.Admin ? undefined : <></>}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="name" label="NAME"/>
				<ImageField source="imageUrl" label="IMAGE"/>
			</SimpleShowLayout>
		</Show>
	);
};
