import { defaultTheme } from 'react-admin';
import { ThemeOptions } from '@mui/material';
import { palette, typography } from './util/Styled';

export const theme: ThemeOptions = {
	...defaultTheme,
	typography,
	palette,
	components: {
		...defaultTheme.components,
		// MuiDrawer: {
		// 	styleOverrides: {
		// 		root: {
		// 			[generalTheme.breakpoints.up(768)]: {
		// 				height: 'unset !important',
		// 				backgroundImage: 'linear-gradient(135deg, #2f3ea0, #ae342d)',
		// 				backgroundRepeat: 'repeat-x',
		// 				boxShadow: '-6px 0 9px #00000054'
		// 			}
		// 		}
		// 	}
		// },
		MuiFilledInput: {
			styleOverrides: {
				underline: {
					'&&&:before': { 'borderBottom': 'none' }
				},
				root: {
					backgroundColor: 'white', boxShadow: '0px 15px 20px #27313f0c !important', borderRadius: '6px'
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					'&&&:before': { 'borderBottom': 'none' }
				},
				root: {
					backgroundColor: 'white !important',
					boxShadow: '0px 15px 20px #27313f0c !important',
					borderRadius: '6px'
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					textAlign: 'start'
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					root: {
						backgroundColor: 'white', boxShadow: '0px 15px 20px #27313f0c !important', borderRadius: '6px'
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#F8F9FC',
					elevation0: { boxShadow: '0px 15px 20px #27313f0c !important' },
					elevation1: { boxShadow: '0px 15px 20px #27313f0c !important' },
					elevation2: { boxShadow: '0px 15px 20px #27313f0c !important' },
					elevation3: { boxShadow: '0px 15px 20px #27313f0c !important' }
				},
				elevation0: { boxShadow: '0px 15px 20px #27313f0c !important' },
				elevation1: { boxShadow: '0px 15px 20px #27313f0c !important' },
				elevation2: { boxShadow: '0px 15px 20px #27313f0c !important' },
				elevation3: { boxShadow: '0px 15px 20px #27313f0c !important' }
			}
		},
		MuiAppBar: {
			styleOverrides: {
				colorSecondary: { color: '#09111f' }
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					height: '3rem'
				},
				head: {
					paddingTop: '1.5rem !important',
					paddingBottom: '1.5rem !important',
					borderBottom: '1px solid rgba(224, 224, 224, 1)'
				},
				body: {
					borderBottom: 'none !important'
				}
			}
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					action: {
						marginRight: 'auto !important',
						marginLeft: '-8px !important'
					}
				},
				action: {
					marginRight: 'auto !important',
					marginLeft: '-8px !important'
				}
			}
		}
	}
};
