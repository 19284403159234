import * as React from 'react';
import { Datagrid, NumberField, ReferenceField, TextField } from 'react-admin';
import './KindergartenDatagrid.scss';

export const KindergartenDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick='edit' className='kindergartens-table' optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
	        <NumberField source='id' label='#'/>
            <TextField source='name' label='NAME'/>
            <TextField source='address.addressStr' label='ADDRESS'/>
	        <ReferenceField source="createdById" reference="employees" label="CREATED_BY">
                <TextField source="fullName"/>
            </ReferenceField>
            
		</Datagrid>
	);
};
