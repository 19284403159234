import * as React from 'react';
import { DateField, EmailField, NumberField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import { PhoneField } from '../components/PhoneField';

export const VendorShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="name" label="NAME"/>
				<PhoneField source="user.phone" label="PHONE"/>
				<EmailField source="user.email" label="EMAIL"/>
				<TextField source="address.addressStr" label="ADDRESS"/>
				<TextField source="companyId" label="COMPANY_ID"/>
				<NumberField source="radiusInKM" label="RADIUS_IN_KM"/>
			</SimpleShowLayout>
		</Show>
	);
};
