import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { EventList } from './EventList';
import { EventDatagrid } from './EventDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Event } from './Event';

export * from './Event';
export * from './EventCreate';
export * from './EventDatagrid';
export * from './EventEdit';
export * from './EventList';
export * from './EventShow';

const EventsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'parentId', header: 'הורה' },
	{ key: 'childrenIds', header: 'ילדים' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'date', header: 'תאריך' },
	{ key: 'locationRemarks', header: 'הערות מיקום' },
	{ key: 'backgroundImageUrl', header: 'תמונת רקע' },
	{ key: 'totalSum', header: 'סכום כולל' },
	{ key: 'numberOfParticipants', header: 'מס׳ משתתפים' },
	{ key: 'didSkipProviders', header: 'דילג על נותני שירות?' },
	{ key: 'allPurchasesHandled', header: 'טופל באופן מלא?' },
	{ key: 'token', header: 'טוקן' }
];

export const EventsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Event) => entity;

	const eventsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('אירועים', exportFields, exportDataMapper)}
				{...props}
				filters={eventsFilter}
				bulkActionButtons={<EventsBulkActionButtons/>}
				hasCreate={false}
				title="EVENTS"
				sort={{ field: 'date', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <EventList/> : <EventDatagrid/>}
			</List>
		</Fragment>
	);
};
