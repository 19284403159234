import * as React from 'react';
import { Datagrid, DateField, ImageField, NumberField, TextField, usePermissions } from 'react-admin';
import './ProductCategoryDatagrid.scss';
import { EUserType } from '../enums';

export const ProductCategoryDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick={permissions === EUserType.Admin ? 'edit' : 'show'} className="product-categories-table"
		          optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="name" label="NAME"/>
			<ImageField source="imageUrl" label="IMAGE"/>
		</Datagrid>
	);
};
