import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	ReferenceInput,
	TextInput,
	useGetIdentity,
	usePermissions
} from 'react-admin';
import { ProductList } from './ProductList';
import { ProductDatagrid } from './ProductDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Product } from './Product';
import { EUserType } from '../enums';

export * from './Product';
export * from './ProductCreate';
export * from './ProductDatagrid';
export * from './ProductEdit';
export * from './ProductList';
export * from './ProductShow';

const ProductsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'sku', header: 'מק״ט' },
	{ key: 'title', header: 'כותרת' },
	{ key: 'description', header: 'תיאור' },
	{ key: 'shortDescription', header: 'תיאור קצר' },
	{ key: 'mainImageUrl', header: 'תמונה' },
	{ key: 'gallery', header: 'גלריה' },
	{ key: 'vendor.name', header: 'יבואן' },
	{ key: 'category', header: 'קטגוריה' },
	{ key: 'subCategory', header: 'תת קטגוריה' },
	{ key: 'tags', header: 'תגיות' },
	{ key: 'giftType', header: 'סוג' },
	{ key: 'price', header: 'מחיר' },
	{ key: 'isInStock', header: 'במלאי?' }
];

export const ProductsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: Product) => entity;
	const { permissions } = usePermissions();
	const { identity } = useGetIdentity();

	const productsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		permissions === EUserType.Admin ? <ReferenceInput
			label="VENDOR"
			source="vendor" reference="vendors"
			alwaysOn
		>
			<AutocompleteInput label="VENDOR" optionText="name"/>
		</ReferenceInput> : <></>,
		// <SelectInput source="giftType" label="GIFT_TYPE" choices={Object.keys(EGiftType).map(key => ({
		// 	id: key,
		// 	name: translator(EGiftType[key])
		// }))}/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('מוצרים', exportFields, exportDataMapper)}
				{...props}
				filters={productsFilter}
				bulkActionButtons={<ProductsBulkActionButtons/>}
				title="PRODUCTS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				filter={permissions === EUserType.Vendor ? { vendor: identity?.vendor?.id } : {}}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ProductList/> : <ProductDatagrid/>}
			</List>
		</Fragment>
	);
};
