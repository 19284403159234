import * as React from 'react';
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const InvitationCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="name" label="NAME" validate={required()}/>
				<UploadImage source="imageUrl" label="IMAGE" validate={required()}/>
				<TextInput source="textColor" label="TEXT_COLOR"/>
				<TextInput source="ctaBackgroundColor" label="CTA_BACKGROUND_COLOR"/>
				<TextInput source="ctaTextColor" label="CTA_TEXT_COLOR"/>
			</SimpleForm>
		</Create>
	);
};
