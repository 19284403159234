import React, { PropsWithChildren } from 'react';
import { FunctionField, useTranslate } from 'react-admin';
import _get from 'lodash-es/get';

export const TranslateField: any = ({ namespace, ...props }: PropsWithChildren<any>) => {
	const translate = useTranslate();

	return (
		<FunctionField {...props}
		               render={(record, source) => translate(`${namespace ? `${namespace}.` : ''}${_get(record, source) || ''}`)}
		/>
	);
};
