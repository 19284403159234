import * as React from 'react';
import { Create, EditProps, SimpleForm, TextInput, useTranslate } from 'react-admin';

export const NotificationsForm = (props: EditProps): React.ReactElement => {
	const translator = useTranslate();

	return (
		<Create {...props} resource="notifications">
			<SimpleForm>
				<h3>{translator('SEND_NOTIFICATION_TO_CLIENTS')}</h3>
				<TextInput label="TITLE" source="title"/>
				<TextInput label="CONTENT" source="body"/>
			</SimpleForm>
		</Create>);
};
