import * as React from 'react';
import { AutocompleteInput, Create, CreateProps, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const ProductSubCategoryCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="name" label="NAME" validate={required()}/>
				<UploadImage source="imageUrl" label="IMAGE" validate={required()}/>
				<ReferenceInput
					label="CATEGORY"
					source="categoryId" reference="product-categories"
					validate={required()}
					alwaysOn
				>
					<AutocompleteInput label="CATEGORY" optionText="name"/>
				</ReferenceInput>

			</SimpleForm>
		</Create>
	);
};
