import * as React from 'react';
import {
	AutocompleteInput,
	BooleanInput,
	Create,
	CreateProps,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { EPurchaseStatus } from '../enums';

export const PurchaseCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="transactionId" label="TRANSACTION_ID"/>
				<ReferenceInput
					label="PRODUCT"
					source="productId" reference="products"
					alwaysOn
				>
					<AutocompleteInput label="PRODUCT" optionText="title"/>
				</ReferenceInput>

				<ReferenceInput
					label="EVENT"
					source="eventId" reference="events"
					alwaysOn
				>
					<AutocompleteInput label="EVENT" optionText="id"/>
				</ReferenceInput>

				<TextInput source="customer.personalId" label="PERSONAL_ID"/>
				<TextInput source="customer.name" label="CUSTOMER_NAME"/>
				<TextInput source="customer.childName" label="CUSTOMER_CHILD_NAME"/>
				<TextInput source="customer.email" label="EMAIL"/>
				<TextInput source="customer" label="PHONE_NUMBER"/>
				<TextInput source="customer.message" label="MESSAGE"/>
				<NumberInput source="sum" label="SUM"/>
				<GoogleAddressInput source="address.addressStr" label="ADDRESS"/>
				<SelectInput source="status" label="STATUS" choices={Object.keys(EPurchaseStatus).map(key => ({
					id: key,
					name: EPurchaseStatus[key]
				}))}/>
				<BooleanInput source="isSettled" label="IS_SETTLED"/>
				<TextInput source="transactionToken" label="TRANSACTION_TOKEN"/>
			</SimpleForm>
		</Create>
	);
};
