import * as React from 'react';
import { NumberField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, } from 'react-admin';

export const KindergartenShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<TextField source="name" label="NAME"/>
				<TextField source="address.addressStr" label="ADDRESS"/>
				<ReferenceField source="createdById" reference="employees" label="CREATED_BY">
					<TextField source="fullName"/>
				</ReferenceField>

			</SimpleShowLayout>
		</Show>
	);
};
