import * as React from 'react';
import {
	DateField,
	ImageField,
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField
} from 'react-admin';
import { PhoneField } from '../components/PhoneField';

export const ServiceProviderShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="name" label="NAME"/>
				<PhoneField source="user.phone" label="PHONE"/>
				<TextField source="address.addressStr" label="ADDRESS"/>
				<TextField source="description" label="DESCRIPTION"/>
				<ImageField source="featuredImageUrl" label="FEATURED_IMAGE"/>
				<ReferenceField source="categoryId" reference="service-provider-categories" label="CATEGORY">
					<TextField source="name"/>
				</ReferenceField>

				<TextField source="companyId" label="COMPANY_ID"/>
				<NumberField source="radiusInKM" label="RADIUS_IN_KM"/>
				<TextField source="title" label="TITLE"/>
			</SimpleShowLayout>
		</Show>
	);
};
