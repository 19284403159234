import * as React from 'react';
import {
	AutocompleteInput,
	Create,
	CreateProps,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput
} from 'react-admin';
import { EProductItemStatus } from '../enums';

export const ProductItemCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect={(
			resource?: string,
			id?: any,
			data?: any,
			state?: object
		) => {
			console.log(resource);
			console.log(id);
			console.log(data);
			console.log(state);

			return `/events/${data.eventId}/show`;
		}}

		>
			<SimpleForm defaultValues={{
				eventId: document.location.href.split('/')[5]
			}}>
				<ReferenceInput
					label="PRODUCT"
					source="productId" reference="products"
					alwaysOn
				>
					<AutocompleteInput label="PRODUCT" optionText="title"/>
				</ReferenceInput>

				<TextInput label="NOTE" source="note"/>
				<SelectInput source="status" label="STATUS" choices={Object.keys(EProductItemStatus).map(key => ({
					id: key,
					name: EProductItemStatus[key]
				}))}/>
			</SimpleForm>
		</Create>
	);
};
