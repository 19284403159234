import * as React from 'react';
import { Datagrid, DateField, ImageField, NumberField, ReferenceField, TextField } from 'react-admin';
import './ServiceProviderDatagrid.scss';
import { PhoneField } from '../components/PhoneField';

export const ServiceProviderDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="service-providers-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="name" label="NAME"/>
			<PhoneField source="user.phone" label="PHONE"/>
			<TextField source="address.addressStr" label="ADDRESS"/>
			<TextField source="description" label="DESCRIPTION"/>
			<ImageField source="featuredImageUrl" label="FEATURED_IMAGE"/>
			<ReferenceField source="categoryId" reference="service-provider-categories" label="CATEGORY">
				<TextField source="name"/>
			</ReferenceField>

			<TextField source="companyId" label="COMPANY_ID"/>
			<NumberField source="radiusInKM" label="RADIUS_IN_KM"/>
			<TextField source="title" label="TITLE"/>
		</Datagrid>
	);
};
