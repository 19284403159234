import * as React from 'react';
import { AutocompleteInput, Edit, EditProps, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';
import GoogleAddressInput from '../components/GoogleAddressInput';

export const ServiceProviderEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="name" label="NAME"/>
				<TextInput source="user.phone.phone" label="PHONE"/>
				<GoogleAddressInput source="address.addressStr" label="ADDRESS"/>
				<TextInput source="description" label="DESCRIPTION"/>
				<UploadImage source="featuredImageUrl" label="FEATURED_IMAGE"/>
				<ReferenceInput
					label="CATEGORY"
					source="categoryId" reference="service-provider-categories"
					alwaysOn
				>
					<AutocompleteInput label="CATEGORY" optionText="name"/>
				</ReferenceInput>

				<TextInput source="companyId" label="COMPANY_ID"/>
				<NumberInput source="radiusInKM" label="RADIUS_IN_KM"/>
				<TextInput source="title" label="TITLE"/>
			</SimpleForm>
		</Edit>
	);
};
