import * as React from 'react';
import { AutocompleteInput, Edit, EditProps, NumberInput, ReferenceInput, SimpleForm } from 'react-admin';

export const ServiceProviderLeadEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<ReferenceInput
					label="SERVICE_PROVIDER"
					source="serviceProviderId" reference="service-providers"
					alwaysOn
				>
					<AutocompleteInput label="SERVICE_PROVIDER" optionText="name"/>
				</ReferenceInput>

				<ReferenceInput
					label="EVENT"
					source="eventId" reference="events"
					alwaysOn
				>
					<AutocompleteInput label="EVENT" optionText="id"/>
				</ReferenceInput>

			</SimpleForm>
		</Edit>
	);
};
