import * as React from 'react';
import { Create, CreateProps, SimpleForm, TextInput } from 'react-admin';

export const EmployeeCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source='fullName' label='FULL_NAME'/>
				<TextInput source='phone.phone' label='PHONE' type="tel"/>
				<TextInput source='email' label='EMAIL'/>
				<TextInput source='password' label='PASSWORD' type="password"/>
			</SimpleForm>
		</Create>
	);
};
