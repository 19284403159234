import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { ServiceProviderList } from './ServiceProviderList';
import { ServiceProviderDatagrid } from './ServiceProviderDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { ServiceProvider } from './ServiceProvider';

export * from './ServiceProvider';
export * from './ServiceProviderCreate';
export * from './ServiceProviderDatagrid';
export * from './ServiceProviderEdit';
export * from './ServiceProviderList';
export * from './ServiceProviderShow';

const ServiceProvidersBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' },
	{ key: 'user.phone', header: 'טלפון' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'description', header: 'תיאור' },
	{ key: 'featuredImageUrl', header: 'תמונה ראשית' },
	{ key: 'categoryId', header: 'קטגוריה' },
	{ key: 'companyId', header: 'מ.ז.' },
	{ key: 'radiusInKM', header: 'רדיוס בק״מ' },
	{ key: 'title', header: 'כותרת' }
];

export const ServiceProvidersData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: ServiceProvider) => entity;

	const serviceProvidersFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('נותני שירות', exportFields, exportDataMapper)}
				{...props}
				filters={serviceProvidersFilter}
				bulkActionButtons={<ServiceProvidersBulkActionButtons/>}
				title="SERVICE_PROVIDERS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ServiceProviderList/> : <ServiceProviderDatagrid/>}
			</List>
		</Fragment>
	);
};
