import * as React from 'react';
import {
	BooleanField,
	ChipField,
	DateField,
	EditButton,
	Identifier,
	ImageField,
	Labeled,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	SingleFieldList,
	TextField,
	UrlField
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Event } from './Event';
import { PriceField } from '../components/PriceField';

const eventStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface EventListProps {
	ids?: Identifier[];
	data?: { [key: number]: Event };
	basePath?: string;
}

export const EventList = (props: EventListProps) => {
	const { ids, data } = props;
	const classes = eventStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="events"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="PARENT" sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="parentId" label="FORUM" reference="parents">
								<TextField source="fullName"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="CHILDREN" sx={classes.cardContentRow}>
							<ReferenceArrayField record={data[id]} source="childrenIds" label="CHILDREN"
							                     reference="children">
								<SingleFieldList>
									<ChipField source="fullName"/>
								</SingleFieldList>
							</ReferenceArrayField>
						</Labeled>
						<Labeled label="ADDRESS" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="address.addressStr" label="ADDRESS"/>
						</Labeled>
						<Labeled label="DATE" sx={classes.cardContentRow}>
							<DateField locales="he-IL" record={data[id]} source="date" label="DATE"/>
						</Labeled>
						<Labeled label="LOCATION_REMARKS" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="locationRemarks" label="LOCATION_REMARKS"/>
						</Labeled>
						<Labeled label="BACKGROUND_IMAGE" sx={classes.cardContentRow}>
							<ImageField record={data[id]} source="backgroundImageUrl" label="BACKGROUND_IMAGE"/>
						</Labeled>
						<Labeled label="TOTAL_SUM" sx={classes.cardContentRow}>
							<PriceField record={data[id]} source="totalSum" label="TOTAL_SUM"/>
						</Labeled>
						<Labeled label="NUMBER_OF_PARTICIPANTS" sx={classes.cardContentRow}>
							<NumberField record={data[id]} source="numberOfParticipants"
							             label="NUMBER_OF_PARTICIPANTS"/>
						</Labeled>
						<Labeled label="DID_SKIP_PROVIDERS" sx={classes.cardContentRow}>
							<BooleanField record={data[id]} source="didSkipProviders" label="DID_SKIP_PROVIDERS"/>
						</Labeled>
						<Labeled label="ALL_PURCHASES_HANDLED" sx={classes.cardContentRow}>
							<BooleanField record={data[id]} source="allPurchasesHandled" label="ALL_PURCHASES_HANDLED"/>
						</Labeled>
						<Labeled label="LINK" sx={classes.cardContentRow}>
							<UrlField source="token"
							          label="LINK"
							          href={`https://bul-gift.com/events/${data[id].token}`}/>
						</Labeled>
						<Labeled label="KINDERGARTEN" sx={classes.cardContentRow}>
							<ReferenceField source="kindergartenId" label="KINDERGARTEN"
							                reference="kindergartens">
								<TextField source="name"/>
							</ReferenceField>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
