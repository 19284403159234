import * as React from 'react';
import {
	AutocompleteInput,
	Create,
	CreateProps,
	ReferenceInput,
	SimpleForm,
	TextInput,
	usePermissions
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { EUserType } from '../enums';

export const KindergartenCreate = (props: CreateProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="name" label="NAME"/>
				<GoogleAddressInput source="address" label="ADDRESS"/>
				{permissions !== EUserType.Employee ? <ReferenceInput
					label="CREATED_BY"
					source="createdById" reference="employees"
					alwaysOn
				>
					<AutocompleteInput label="CREATED_BY" optionText="fullName"/>
				</ReferenceInput> : null}

			</SimpleForm>
		</Create>
	);
};
