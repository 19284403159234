import * as React from 'react';
import { Edit, EditProps, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import { UploadImage } from '../components/UploadImage';

export const ProductCategoryEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<NumberInput source="id" label="#" disabled/>
				<TextInput source="name" label="NAME" validate={required()}/>
				<UploadImage source="imageUrl" label="IMAGE" validate={required()}/>
			</SimpleForm>
		</Edit>
	);
};
