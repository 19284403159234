import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { BulPostList } from './BulPostList';
import { BulPostDatagrid } from './BulPostDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { BulPost } from './BulPost';

export * from './BulPost';
export * from './BulPostCreate';
export * from './BulPostDatagrid';
export * from './BulPostEdit';
export * from './BulPostList';
export * from './BulPostShow';

const BulPostsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'title', header: 'כותרת' },
	{ key: 'description', header: 'תיאור' },
	{ key: 'imageUrl', header: 'תמונה' },
	{ key: 'link', header: 'קישור' }
];

export const BulPostsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: BulPost) => entity;

	const bulPostsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('פוסטים על בול', exportFields, exportDataMapper)}
				{...props}
				filters={bulPostsFilter}
				bulkActionButtons={<BulPostsBulkActionButtons/>}
				title="BUL_POSTS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <BulPostList/> : <BulPostDatagrid/>}
			</List>
		</Fragment>
	);
};
