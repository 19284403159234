import * as React from 'react';
import { AutocompleteInput, Create, CreateProps, ReferenceInput, SimpleForm } from 'react-admin';

export const ServiceProviderLeadCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<ReferenceInput
					label="SERVICE_PROVIDER"
					source="serviceProviderId" reference="service-providers"
					alwaysOn
				>
					<AutocompleteInput label="SERVICE_PROVIDER" optionText="name"/>
				</ReferenceInput>

				<ReferenceInput
					source="eventId" reference="events"
					alwaysOn
				>
					<AutocompleteInput label="EVENT" optionText="id"/>
				</ReferenceInput>

			</SimpleForm>
		</Create>
	);
};
