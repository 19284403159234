import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	ReferenceInput,
	TextInput
} from 'react-admin';
import { ProductSubCategoryList } from './ProductSubCategoryList';
import { ProductSubCategoryDatagrid } from './ProductSubCategoryDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { ProductSubCategory } from './ProductSubCategory';

export * from './ProductSubCategory';
export * from './ProductSubCategoryCreate';
export * from './ProductSubCategoryDatagrid';
export * from './ProductSubCategoryEdit';
export * from './ProductSubCategoryList';
export * from './ProductSubCategoryShow';

const ProductSubCategoriesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' },
	{ key: 'imageUrl', header: 'תמונה' },
	{ key: 'categoryId', header: 'קטגוריה' }
];

export const ProductSubCategoriesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: ProductSubCategory) => entity;

	const productSubCategoriesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<ReferenceInput
			label="CATEGORY"
			source="categoryId" reference="product-categories"
			alwaysOn={!isXSmall}
		>
			<AutocompleteInput label="CATEGORY" optionText="name"/>
		</ReferenceInput>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('תתי קטגוריות מוצרים', exportFields, exportDataMapper)}
				{...props}
				filters={productSubCategoriesFilter}
				bulkActionButtons={<ProductSubCategoriesBulkActionButtons/>}
				title="PRODUCT_SUB_CATEGORIES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ProductSubCategoryList/> : <ProductSubCategoryDatagrid/>}
			</List>
		</Fragment>
	);
};
