import * as React from 'react';
import { Datagrid, DateField, ImageField, TextField, UrlField } from 'react-admin';
import './AdDatagrid.scss';

export const AdDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="ads-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="title" label="TITLE"/>
			<ImageField source="imageUrl" label="IMAGE"/>
			<DateField locales="he-IL" source="startDate" label="START_DATE" showTime/>
			<DateField locales="he-IL" source="finishDate" label="FINISH_DATE" showTime/>
			<UrlField source="link" label="LINK"/>
			<TextField source="buttonText" label="BUTTON_TEXT"/>
			<TextField source="id" label="#"/>
			<DateField showTime locales='he-IL' source="_createdAt" label="CREATED_AT"/>
			<DateField showTime locales='he-IL' source="_updatedAt" label="UPDATED_AT"/>
		</Datagrid>
	);
};
