import * as React from 'react';
import { Datagrid, DateField, NumberField, TextField } from 'react-admin';
import './ProductTagDatagrid.scss';

export const ProductTagDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="product-tags-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="name" label="NAME"/>
		</Datagrid>
	);
};
