import * as React from 'react';
import { useState } from 'react';
import { PriceField } from '../components/PriceField';
import {
	ArrayField,
	BooleanField,
	Button,
	ChipField,
	Datagrid,
	DateField,
	ImageField,
	List,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	Show,
	ShowProps,
	SingleFieldList,
	Tab,
	TabbedShowLayout,
	TextField,
	UrlField,
	useRecordContext, useTranslate,
	WithRecord
} from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import Edit from '@mui/icons-material/Edit';
import { Drawer } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ProductItemEdit } from './ProductItemEdit';
import { Link } from 'react-router-dom';

export const EventShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<EventTabbedShow/>
		</Show>
	);
};

export const EventTabbedShow = (props) => {
	const record = useRecordContext();
	const translator = useTranslate();

	console.log(record);

	return <TabbedShowLayout {...props}>
		<Tab label="DETAILS">
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<ReferenceField source="parentId"
			                reference="parents" label="PARENT">
				<TextField source="fullName"/>
			</ReferenceField>
			<ReferenceArrayField source="childrenIds" label="CHILDREN" reference="children">
				<SingleFieldList>
					<ChipField source="fullName"/>
				</SingleFieldList>
			</ReferenceArrayField>
			<TextField source="address.addressStr" label="ADDRESS"/>
			<DateField source="date" label="DATE"/>
			<TextField source="locationRemarks" label="LOCATION_REMARKS"/>
			<ImageField source="backgroundImageUrl" label="BACKGROUND_IMAGE"/>
			<PriceField source="totalSum" label="TOTAL_SUM"/>
			<NumberField source="numberOfParticipants" label="NUMBER_OF_PARTICIPANTS"/>
			<BooleanField source="didSkipProviders" label="DID_SKIP_PROVIDERS"/>
			<BooleanField source="allPurchasesHandled" label="ALL_PURCHASES_HANDLED"/>
			<ReferenceField source="kindergartenId" label="KINDERGARTEN"
			                 reference="kindergartens">
				<TextField source="name"/>
			</ReferenceField>
			<WithRecord label="LINK" render={record =>
				<UrlField source="token"
				          label="LINK"
				          href={`https://bul-gift.com/events/${record.token}`}/>}/>
		</Tab>
		<Tab label="PRODUCT_ITEMS" path="product-items">
			<ArrayField source="productItems" label="">
				<List
					resource="product-items"
					actions={<Button><Link to={`/events/${record?.id}/product-items/create`}>{translator('ADD_PRODUCT_ITEM')}</Link></Button>}
					filter={{ event: record?.id }}
				>
					<Datagrid optimized>
						<ReferenceField source="productId" reference="products" label="PRODUCT">
							<TextField source="title" label="PRODUCT"/>
						</ReferenceField>
						<TextField label="PURCHASES" source="purchases"/>
						<TextField label="NOTE" source="note"/>
						<TranslateField label="STATUS" source="status"/>
						<BooleanField label="PURCHASED" source="purchased"/>
						<PriceField label="TOTAL_PURCHASED_SUM" source="totalPurchasedSum"/>
						<NumberField label="NUMBER_OF_PURCHASES" source="numberOfPurchases"/>
						<EditProductItemButton/>
					</Datagrid>
				</List>
			</ArrayField>
		</Tab>
	</TabbedShowLayout>;
};

const EditProductItemButton = () => {
	const product = useRecordContext();

	const [showPanel, setShowPanel] = useState(false);

	const handleClick = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setShowPanel(true);
	};

	const handleCloseClick = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setShowPanel(false);
	};

	return (
		<>
			<Button onClick={handleClick} label={product.title} sx={{ whiteSpace: 'no-wrap' }}>
				<Edit/>
			</Button>
			<Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
				<div>
					<Button label="CLOSE" onClick={handleCloseClick}>
						<KeyboardArrowRight/>
					</Button>
				</div>
				<ProductItemEdit resource="product-items" id={product.id}/>
			</Drawer>
		</>
	);
};

