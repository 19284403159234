import * as React from 'react';
import { EditButton, Identifier, ImageField, Labeled, ReferenceField, TextField } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

import { MissingProduct } from './MissingProduct';

const missingProductStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface MissingProductListProps {
	ids?: Identifier[];
	data?: { [key: number]: MissingProduct };
	basePath?: string;
}

export const MissingProductList = (props: MissingProductListProps) => {
	const { ids, data } = props;
	const classes = missingProductStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="missing-products"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label='#' sx={classes.cardContentRow}>
							<TextField record={data[id]} source='id' label='#'/>
						</Labeled>
						<Labeled label='TITLE' sx={classes.cardContentRow}>
                            <TextField record={data[id]} source='title' label='TITLE'/>
						</Labeled>
						<Labeled label='DESCRIPTION' sx={classes.cardContentRow}>
                            <TextField record={data[id]} source='description' label='DESCRIPTION'/>
						</Labeled>
						<Labeled label='CAN_BE_FOUND_IN' sx={classes.cardContentRow}>
                            <TextField record={data[id]} source='canBeFoundIn' label='CAN_BE_FOUND_IN'/>
						</Labeled>
						<Labeled label='IMAGE' sx={classes.cardContentRow}>
                            <ImageField record={data[id]} source='imageUrl' label='IMAGE'/>
						</Labeled>
						<Labeled label='PARENT' sx={classes.cardContentRow}>
							<ReferenceField record={data[id]} source="parentId" label="FORUM" reference="parents">
                                <TextField source="fullName"/>
                            </ReferenceField>
							
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
