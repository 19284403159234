import * as React from 'react';
import { Datagrid, ImageField, NumberField, TextField, UrlField } from 'react-admin';
import './BulPostDatagrid.scss';

export const BulPostDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="bul-posts-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<TextField source="title" label="TITLE"/>
			<TextField source="description" label="DESCRIPTION"/>
			<ImageField source="imageUrl" label="IMAGE"/>
			<UrlField source="link" label="LINK"/>
		</Datagrid>
	);
};
