import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput } from 'react-admin';
import { ServiceProviderCategoryList } from './ServiceProviderCategoryList';
import { ServiceProviderCategoryDatagrid } from './ServiceProviderCategoryDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { ServiceProviderCategory } from './ServiceProviderCategory';

export * from './ServiceProviderCategory';
export * from './ServiceProviderCategoryCreate';
export * from './ServiceProviderCategoryDatagrid';
export * from './ServiceProviderCategoryEdit';
export * from './ServiceProviderCategoryList';
export * from './ServiceProviderCategoryShow';

const ServiceProviderCategoriesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם' },
	{ key: 'imageUrl', header: 'תמונה' }
];

export const ServiceProviderCategoriesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
	const exportDataMapper = (entity: ServiceProviderCategory) => entity;

	const serviceProviderCategoriesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('קטגוריות נותני שירות', exportFields, exportDataMapper)}
				{...props}
				filters={serviceProviderCategoriesFilter}
				bulkActionButtons={<ServiceProviderCategoriesBulkActionButtons/>}
				title="SERVICE_PROVIDER_CATEGORIES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <ServiceProviderCategoryList/> : <ServiceProviderCategoryDatagrid/>}
			</List>
		</Fragment>
	);
};
