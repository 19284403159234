import * as React from 'react';
import {
	AutocompleteInput,
	ChipField,
	Create,
	CreateProps,
	DateTimeInput,
	NumberInput,
	ReferenceArrayField,
	ReferenceField, ReferenceInput,
	SimpleForm,
	SingleFieldList,
	TextField,
	TextInput
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { UploadImage } from '../components/UploadImage';

export const EventCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<ReferenceField source="parentId" reference="parents" label="PARENT">
					<TextField source="fullName"/>
				</ReferenceField>
				<ReferenceArrayField source="childrenIds" label="CHILDREN" reference="children">
					<SingleFieldList>
						<ChipField source="fullName"/>
					</SingleFieldList>
				</ReferenceArrayField>
				<GoogleAddressInput source="address" label="ADDRESS"/>
				<DateTimeInput source="date" label="DATE"/>
				<TextInput source="locationRemarks" label="LOCATION_REMARKS"/>
				<UploadImage source="backgroundImageUrl" label="BACKGROUND_IMAGE"/>
				<NumberInput source="numberOfParticipants" label="NUMBER_OF_PARTICIPANTS"/>
				<TextInput source="token" label="TOKEN"/>
				<ReferenceInput
					label="KINDERGARTEN"
					source="kindergartenId" reference="kindergartens"
					alwaysOn
				>
					<AutocompleteInput label="PARENT" optionText="name"/>
				</ReferenceInput>
			</SimpleForm>
		</Create>
	);
};
