import * as React from 'react';
import { Datagrid, ImageField, NumberField, ReferenceField, TextField } from 'react-admin';
import './MissingProductDatagrid.scss';

export const MissingProductDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick='edit' className='missing-products-table' optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
	        <NumberField source='id' label='#'/>
            <TextField source='title' label='TITLE'/>
            <TextField source='description' label='DESCRIPTION'/>
            <TextField source='canBeFoundIn' label='CAN_BE_FOUND_IN'/>
            <ImageField source='imageUrl' label='IMAGE'/>
	        <ReferenceField source="parentId" reference="parents" label="PARENT">
                <TextField source="fullName"/>
            </ReferenceField>
            
		</Datagrid>
	);
};
