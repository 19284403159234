import * as React from 'react';
import {
	DateField,
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	UrlField,
	usePermissions
} from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import { EUserType } from '../enums';

export const ChildShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={permissions === EUserType.Admin ? undefined : false}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<TextField source="fullName"
				           label="LOCATION_REMARKS"/>
				<ReferenceField source="parentId" reference="parents" label="PARENT">
					<TextField source="fullName"/>
				</ReferenceField>

				<UrlField source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TranslateField source="gender" label="GENDER"/>
				<DateField locales="he-IL" source="birthDate" label="BIRTH_DATE"/>
				<NumberField source="numberOfFriends" label="NUMBER_OF_FRIENDS"/>
				<NumberField source="shoeSize" label="SHOE_SIZE"/>
				<NumberField source="shirtSize" label="SHIRT_SIZE"/>
				<NumberField source="pantsSize" label="PANTS_SIZE"/>
			</SimpleShowLayout>
		</Show>
	);
};
