import * as React from 'react';
import { DateField, EditButton, Identifier, ImageField, Labeled, TextField, UrlField } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Ad } from './Ad';

const serviceProviderStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface AdListProps {
	ids?: Identifier[];
	data?: { [key: number]: Ad };
	basePath?: string;
}

export const AdList = (props: AdListProps) => {
	const { ids, data } = props;
	const classes = serviceProviderStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="service-providers"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="CREATED_AT" sx={classes.cardContentRow}>
							<DateField showTime locales='he-IL' record={data[id]} source="_createdAt" label="CREATED_AT"/>
						</Labeled>
						<Labeled label="UPDATED_AT" sx={classes.cardContentRow}>
							<DateField showTime locales='he-IL' record={data[id]} source="_updatedAt" label="UPDATED_AT"/>
						</Labeled>
						<Labeled label="TITLE" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="title" label="TITLE"/>
						</Labeled>
						<Labeled label="IMAGE" sx={classes.cardContentRow}>
							<ImageField record={data[id]} source="imageUrl" label="IMAGE"/>
						</Labeled>
						<Labeled label="START_DATE" sx={classes.cardContentRow}>
							<DateField showTime locales="he-IL" record={data[id]} source="startDate" label="START_DATE"/>
						</Labeled>
						<Labeled label="FINISH_DATE" sx={classes.cardContentRow}>
							<DateField showTime locales="he-IL" record={data[id]} source="finishDate" label="FINISH_DATE"/>
						</Labeled>
						<Labeled label="LINK" sx={classes.cardContentRow}>
							<UrlField record={data[id]} source="link" label="LINK"/>
						</Labeled>
						<Labeled label="BUTTON_TEXT" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="buttonText" label="BUTTON_TEXT"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
