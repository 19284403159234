import * as React from 'react';
import { Datagrid, DateField, NumberField, ReferenceField, TextField, UrlField } from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import './ChildDatagrid.scss';
import { PhoneField } from '../components/PhoneField';

export const ChildDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="show" className="children-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
			<TextField source="fullName" label="LOCATION_REMARKS"/>
			<ReferenceField source="parentId" reference="parents" label="PARENT" link="show">
				<TextField source="fullName"/>
			</ReferenceField>
			<ReferenceField source="parentId" reference="parents" label="PARENT_PHONE" link="show">
				<PhoneField source="user.phone"/>
			</ReferenceField>
			<UrlField source="profileImageUrl" label="PROFILE_IMAGE"/>
			<TranslateField source="gender" label="GENDER"/>
			<DateField locales="he-IL" source="birthDate" label="BIRTH_DATE"/>
			<NumberField source="numberOfFriends" label="NUMBER_OF_FRIENDS"/>
			<NumberField source="shoeSize" label="SHOE_SIZE"/>
			<NumberField source="shirtSize" label="SHIRT_SIZE"/>
			<NumberField source="pantsSize" label="PANTS_SIZE"/>
		</Datagrid>
	);
};
