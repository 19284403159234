import {
	CreateParams,
	DeleteManyParams,
	DeleteParams,
	fetchUtils,
	GetListParams,
	GetManyParams,
	GetManyReferenceParams,
	GetOneParams,
	UpdateManyParams,
	UpdateParams,
	UseDataProviderOptions
} from 'react-admin';
import { stringify } from 'query-string';
import { serialize } from '../util/Serialize';
import { formatQueryParams } from '../util/Formatters';
import { EPlatform } from '../enums';

export const apiUrl = `${process.env.REACT_APP_SERVER_URL}`;

const awesomePromise = async <T>(promise: Promise<T>): Promise<[T, Error & any | null]> => {
	try {
		return [await promise, null];
	} catch (e) {
		return [null as unknown as T, e];
	}
};

export const httpClient = async (url: string, options: { headers?: Headers, method?: string, body?: any } = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}

	const jwtData = localStorage.getItem('jwt-token');

	if (jwtData) {
		const { jwt } = JSON.parse(jwtData);
		options.headers.set('Authorization', `JWT ${jwt}`);
	}

	options.headers.set('Platform', EPlatform.AdminPanel);

	const [data, error] = await awesomePromise(fetchUtils.fetchJson(url, options));

	if (error) throw Error(error?.body?.error?.errorMsgCode);

	return data;
};

const DataProvider = {
	getList: (resource: string, params: GetListParams) => {
		const properties = formatQueryParams(params);

		const url = `${apiUrl}/admin/${resource}?${serialize(properties)}`;

		return httpClient(url).then(({ headers, json }) => {
			return {
				data: json.data.entities,
				total: json.data.total
			};
		});
	},

	getOne: (resource: string, params: GetOneParams, options?: UseDataProviderOptions) =>
		httpClient(`${apiUrl}/admin/${resource}/${params.id}`).then(({ json }) => ({
			data: json.data
		})),

	getMany: (resource: string, params: GetManyParams, options?: UseDataProviderOptions) => {
		const ids: string[] = params.ids?.length ? ((params.ids[0] as any)?.id ? params.ids.map((e: any) => e.id) : params.ids) : [];
		const url = `${apiUrl}/admin/${resource}?ids=[${ids.join(',')}]`;

		return httpClient(url).then(({ headers, json }) => {
			return {
				data: json.data.entities,
				total: json.data.total
			};
		});
	},

	getManyReference: (resource: string, params: GetManyReferenceParams, options?: UseDataProviderOptions) => {
		const properties = formatQueryParams({
			...params,
			filter: { ...params.filter ?? {}, [params.target]: params.id }
		});

		const url = `${apiUrl}/admin/${resource}?${serialize(properties)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json.data?.entities,
			total: json.data?.total ?? parseInt(headers.get('content-range')?.split('/')?.pop() ?? '0', 10)
		}));
	},

	update: (resource: string, params: UpdateParams, options?: UseDataProviderOptions) =>
		httpClient(`${apiUrl}/admin/${resource}/${params.id}`, {
			method: 'PUT',
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json.data })),

	updateMany: (resource: string, params: UpdateManyParams) => {
		const query = {
			filter: JSON.stringify({ id: params.data.ids })
		};
		return httpClient(`${apiUrl}/admin/${resource}?${stringify(query)}`, {
			method: 'PUT',
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json.data }));
	},

	create: (resource: string, params: CreateParams) =>
		httpClient(`${apiUrl}/admin/${resource}`, {
			method: 'POST',
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json.data })),

	delete: (resource: string, params: DeleteParams) =>
		httpClient(`${apiUrl}/admin/${resource}/${params.id}`, {
			method: 'DELETE'
		}).then(({ json }) => ({ data: json.data })),

	deleteMany: (resource: string, params: DeleteManyParams) => {
		return httpClient(`${apiUrl}/admin/${resource}?${stringify(params)}`, {
			method: 'DELETE'
		}).then(({ json }) => ({ data: json.data }));
	}
};

export default DataProvider;
