import * as React from 'react';
import { ImageField, NumberField, Show, ShowProps, SimpleShowLayout, TextField, UrlField } from 'react-admin';

export const BulPostShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source="id" label="#"/>
				<TextField source="title" label="TITLE"/>
				<TextField source="description" label="DESCRIPTION"/>
				<ImageField source="imageUrl" label="IMAGE"/>
				<UrlField source="link" label="LINK"/>
			</SimpleShowLayout>
		</Show>
	);
};
