import * as React from 'react';
import {
	BooleanField,
	ChipField,
	DateField,
	ImageField,
	NumberField,
	ReferenceArrayField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	SingleFieldList,
	TextField, usePermissions
} from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import { EUserType } from '../enums';

export const ProductSimpleShow = (...props) => {
	console.log(props);
	const { permissions } = usePermissions();

	return <SimpleShowLayout {...props}>
		<NumberField source="id" label="#"/>
		<DateField locales="he-IL" source="_createdAt" label="CREATED_AT"/>
		<TextField source="sku" label="SKU"/>
		<TextField source="title" label="TITLE"/>
		<TextField source="description" label="DESCRIPTION"/>
		<TextField source="shortDescription" label="SHORT_DESCRIPTION"/>
		<ImageField source="mainImageUrl" label="IMAGE"/>
		<ImageField source="gallery" label="GALLERY"/>
		{permissions === EUserType.Admin &&
            <ReferenceField source="vendorId" reference="vendors" label="VENDOR">
                <TextField source="name"/>
            </ReferenceField>
		}

		<ReferenceField source="categoryId" reference="product-categories" label="CATEGORY">
			<TextField source="name"/>
		</ReferenceField>

		<ReferenceField source="subCategoryId" reference="product-sub-categories" label="SUB_CATEGORY">
			<TextField source="name"/>
		</ReferenceField>

		<ReferenceArrayField source="tagsIds" label="TAGS"
		                     reference="product-tags">
			<SingleFieldList>
				<ChipField source="name"/>
			</SingleFieldList>
		</ReferenceArrayField>
		<TranslateField source="giftType" label="GIFT_TYPE"/>
		<NumberField source="price" label="PRICE"/>
		<BooleanField source="isInStock" label="IS_IN_STOCK"/>
	</SimpleShowLayout>;
};

export const ProductShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<ProductSimpleShow/>
		</Show>
	);
};
