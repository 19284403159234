import * as React from 'react';
import { ImageField, NumberField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, } from 'react-admin';

export const MissingProductShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<NumberField source='id' label='#'/>
				<TextField source='title' label='TITLE'/>
				<TextField source='description' label='DESCRIPTION'/>
				<TextField source='canBeFoundIn' label='CAN_BE_FOUND_IN'/>
				<ImageField source='imageUrl' label='IMAGE'/>
	                <ReferenceField source="parentId" reference="parents" label="PARENT">
	                    <TextField source="fullName"/>
	                </ReferenceField>
	                
			</SimpleShowLayout>
		</Show>
	);
};
