import * as React from 'react';
import { EditButton, Identifier, ImageField, Labeled, TextField, UrlField } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

import { BulPost } from './BulPost';

const bulPostStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

interface BulPostListProps {
	ids?: Identifier[];
	data?: { [key: number]: BulPost };
	basePath?: string;
}

export const BulPostList = (props: BulPostListProps) => {
	const { ids, data } = props;
	const classes = bulPostStyle;

	if (!ids || !data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{ids.map(id => (
				<Card key={id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
                                <span>
                                    {data[id].name}
                                </span>
								<EditButton
									resource="bul-posts"
									record={data[id]}
								/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="id" label="#"/>
						</Labeled>
						<Labeled label="TITLE" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="title" label="TITLE"/>
						</Labeled>
						<Labeled label="DESCRIPTION" sx={classes.cardContentRow}>
							<TextField record={data[id]} source="description" label="DESCRIPTION"/>
						</Labeled>
						<Labeled label="IMAGE" sx={classes.cardContentRow}>
							<ImageField record={data[id]} source="imageUrl" label="IMAGE"/>
						</Labeled>
						<Labeled label="LINK" sx={classes.cardContentRow}>
							<UrlField record={data[id]} source="link" label="LINK"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
