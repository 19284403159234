import * as React from 'react';
import { Edit, EditProps, NumberInput, SimpleForm, TextInput, } from 'react-admin';

export const EmployeeEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode='pessimistic'>
			<SimpleForm>
                <NumberInput source='id' label='#' disabled/>
                <TextInput source='fullName' label='FULL_NAME'/>
                <TextInput source='phone.phone' label='PHONE' type="tel"/>
                <TextInput source='email' label='EMAIL'/>
                <TextInput source='password' label='PASSWORD' type="password"/>
			</SimpleForm>
		</Edit>
	);
};
