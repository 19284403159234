import * as React from 'react';
import { DateField, Show, ShowProps, SimpleShowLayout, TextField, UrlField } from 'react-admin';

export const AdShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<DateField showTime locales="he-IL" source="_createdAt" label="CREATED_AT"/>
				<DateField showTime locales="he-IL" source="_updatedAt" label="UPDATED_AT"/>
				<TextField source="title" label="TITLE"/>
				<UrlField source="imageUrl" label="IMAGE"/>
				<DateField source="startDate" label="START_DATE"/>
				<DateField source="finishDate" label="FINISH_DATE"/>
				<UrlField source="link" label="LINK"/>
			</SimpleShowLayout>
		</Show>
	);
};
