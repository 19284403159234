import * as React from 'react';
import { Create, CreateProps, SimpleForm, TextInput } from 'react-admin';

export const ProductTagCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="name" label="NAME"/>
			</SimpleForm>
		</Create>
	);
};
